<template>
    <v-card class="mt-5" >
      <v-dialog v-model="dialog" persistent max-width="800px">
<!--      <template v-slot:activator="{ on }">-->
<!--        <v-btn color="primary" dark v-on="on" @click="removeSpace()">teste</v-btn>-->
<!--      </template>-->
      <v-card id="box">
        <v-card-title class="justify-center">
          <span class="headline">Ocorrência</span>
        </v-card-title>
        <v-card-text>
          <v-container>
            
              <v-row>
                <v-col cols="12" sm="6" >
                  <v-text-field disabled filled rounded dense label="Usuario" required v-model="usuario"></v-text-field>
                  <v-text-field disabled filled rounded dense label="Nome Helper" v-model="nomeHelper"></v-text-field>
                </v-col>
                <v-col  cols="12" sm="5"  class=" align-self-center" >
                  <!-- <v-checkbox class="io" v-model="iO" label="Botão de Emergência" value="botao" color="green"></v-checkbox>
                  <v-checkbox class="io" v-model="iO" label="Vandalismo" value="Vandalismo" color="red"></v-checkbox>
                  <v-checkbox class="io" v-model="iO" label="Porta Aberta" value="porta aberta" color="yellow"></v-checkbox>
                  <v-checkbox class="io" v-model="iO" label="Alagamento" value="Alagamento" color="blue"></v-checkbox> -->
                  <v-alert class="alerta font-weight-black" elevation="5" dense border="left" type="error" dark>
                  BOTÃO DE EMERGÊNCIA
                  </v-alert>
                  <v-alert class="alerta font-weight-black" elevation="5" dense border="left" type="error" dark>
                  VANDALISMO
                  </v-alert>
                  <v-alert class="alerta font-weight-black" elevation="5" dense border="left" type="error" dark>
                  PORTA ABERTA
                  </v-alert>
                  <v-alert class="alerta font-weight-black" elevation="5" dense border="left" type="error" dark>
                  ALAGAMENTO
                  </v-alert>
                  
                </v-col>

              </v-row>
               <span class="headline">Natureza</span>
              <v-row>
               
                 <v-col cols="12" md="4" >
                  <v-sheet>
                    <v-checkbox :rules="rules.nat" required class="natureza" v-model="natureza" label="Roubo" value="Roubo"></v-checkbox>
                    <v-checkbox :rules="rules.nat" required class="natureza" v-model="natureza" label="Furto" value="Furto"></v-checkbox>
                    <v-checkbox :rules="rules.nat" required class="natureza" v-model="natureza" label="Violência contra mulher" value="Violência contra mulheres"></v-checkbox>
                    <v-checkbox :rules="rules.nat" required class="natureza" v-model="natureza" label="Violência" value="Violência"></v-checkbox>
                    <v-checkbox :rules="rules.nat" required class="natureza" v-model="natureza" label="Informções" value="Informções"></v-checkbox>
                  
                  </v-sheet>

                </v-col>
                <v-col cols="12" md="4" >
                  <v-sheet>
                    <v-checkbox :rules="rules.nat" required class="natureza" v-model="natureza" label="Pedido de Socorro" value="Pedido de Socorro"></v-checkbox>
                    <v-checkbox :rules="rules.nat" required class="natureza" v-model="natureza" label="Manifestações" value="Manifestação"></v-checkbox>
                    <v-checkbox :rules="rules.nat" required class="natureza" v-model="natureza" label="Acidente de transito" value="Acidente de transito"></v-checkbox>
                    <v-checkbox :rules="rules.nat" required class="natureza" v-model="natureza" label="Depedração Pública" value="Depedração Pública"></v-checkbox>
                    <v-checkbox :rules="rules.nat" required class="natureza" v-model="natureza" label="Pichação" value="Pichação"></v-checkbox>
                  
                  </v-sheet>

                </v-col>
                <v-col cols="12" md="4" >
                  <v-sheet>
                    <v-checkbox :rules="rules.nat" required class="natureza" v-model="natureza" label="Outros - Informar a natureza no campo descrição" value="Outros"></v-checkbox>
                  </v-sheet>

                </v-col>
              </v-row>
              <v-row class="pt-6">
                <v-col cols="12">
                  <v-textarea
                    outlined
                    required
                    :rules="rules.texto"
                    label="Descrição"
                    v-model="descricao"
                    
                  >
                  </v-textarea>
                </v-col>
               
           </v-row>
          </v-container>
          <small class="red--text">*É obrigatorio registar as informações</small>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn :disabled="!formIsValid" type="submit" color="blue darken-1" text @click.prevent="salvar()">Registrar</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    </v-card>

</template>

<script>
    import $ from 'jquery'
export default {
  name:"Ocorrencia",
    data(){
        return{
            dialog: false,
            descricao:'',
            usuario: JSON.parse(sessionStorage.user).user.nome,
            email: JSON.parse(sessionStorage.user).user.email,
            nomeHelper: "",
            iO:[],
            natureza:[],
            setor:JSON.parse(sessionStorage.user).user.setor,
            rules: {
            texto: [val => (val || '').length > 0 || 'Não pode ser vazio'],
            nat: [val => (val || '').length > 0 || 'Não pode ser vazio'],
            },
        }
    },
     computed: {
      formIsValid () {
        return (
          this.natureza[0] &&
          this.descricao

        )
        },
    },
    methods:{
        removeSpace(){
            setTimeout(() => {
                $('.io>div.v-input__control>div.v-messages').remove()
                $('.io>div.v-input__control>div.v-input__slot').css({marginBottom:"2px"})
                $('.natureza>div.v-input__control>div.v-messages').remove()
                $('.natureza>div.v-input__control>div.v-input__slot').css({marginBottom:"2px"})
            }, 100)
        },
        salvar(){
            let ocorrencia ={
            usuario: this.usuario,
            email:this.email,
            setor:this.setor,
            nomeHelper: this.nomeHelper,
            iO: this.iO,
            natureza: this.natureza,
            descricao: this.descricao

            }
            //console.log(ocorrencia)
            this.dialog = false
        

        }
    },
    mounted(){
      this.axios.defaults.headers.common['Authorization'] = 'Bearer '+ JSON.parse(sessionStorage.getItem('user')).token;
    }

}
</script>
<style scoped>
.io {
  margin-bottom: 0px;
  margin-top: 0px; 
  padding-bottom: 0px;
  
}


.natureza{
  margin-bottom: 0px;
  margin-top: 0px; 
  padding-bottom: 0px;
}

</style>