<template xmlns:v-slot="http://www.w3.org/1999/XSL/Transform">
    <div>
      <loading-overlay :active="page.loading.isLoading" :is-full-page="page.loading.fullPage"/>
        <!--    region cadastro helper-->
        <v-toolbar flat dark>
            <v-toolbar-title>{{this.tabTitle}}</v-toolbar-title>
        </v-toolbar>
        <v-tabs color="grey lighten-2" vertical v-model="page.abaAtiva">
            <v-tab @click="tabTitle = 'Cadastro Helpers'">
            <v-icon left>mdi-pencil</v-icon><span class="d-none d-sm-flex">Cadastro</span>
            <v-spacer></v-spacer>
          </v-tab>
            <v-tab @click="tabTitle = 'Lista de Helpers Cadastrados'">
              <v-icon left>mdi-table</v-icon><span class="d-none d-sm-flex">Lista</span>
              <v-spacer></v-spacer>
            </v-tab>

            <v-tab-item>
                <v-container>
                    <v-form ref="form" lazy-validation>
                        <v-row>
                            <v-col cols="12" md="8" >
                                <v-text-field
                                    v-model="entity.properties.nome"
                                    label="Nome do Helper"
                                    name="nome"
                                    class="purple-input"
                                    :rules="fieldRequired"
                                    placeholder="Helper"
                                />
                            </v-col>
                            <v-col cols="12" md="4" >
                              <v-text-field
                                  v-model="entity.properties.setor"
                                  label="Setor"
                                  name="setor"
                                  class="purple-input"
                                  :rules="fieldRequired"
                                  placeholder="SETOR"
                              />
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col cols="12">
                              <v-text-field
                                  v-model="entity.properties.ip_helper"
                                  label="IP do Helper"
                                  name="ip"
                                  class="purple-input"
                                  :rules="fieldRequired"
                                  placeholder="192.168.200.1"
                              />
                            </v-col>
                        </v-row>
                        <h4 class="title">Localização</h4>
                        <v-row>
                            <v-col cols="12" md="6" >
                              <v-text-field
                                  v-model="entity.geometry.coordinates[1]"
                                  label="Latitude"
                                  name="latitude"
                                  type="number"
                                  class="purple-input"
                                  :rules="coordinatesRules"
                              />
                            </v-col>

                            <v-col cols="12" md="6" >
                              <v-text-field
                                  v-model="entity.geometry.coordinates[0]"
                                  label="Longitude"
                                  name="longitude"
                                  type="number"
                                  class="purple-input"
                                  :rules="coordinatesRules"
                              />
                            </v-col>
                        </v-row>

                        <v-row>
                          <!--BUTTONS -->
                          <v-col class="align-content-end">
                            <v-btn v-if="checkExist()" class="mr-2" color="info" @click.prevent="editData()">
                              Atualizar
                            </v-btn>
                            <v-btn v-if="!checkExist()" class="mr-2" color="success" @click.prevent="addData()">
                              Salvar
                            </v-btn>
                            <v-btn class="mr-2" color="warning" @click.prevent="clearForm()">
                              Cancelar
                            </v-btn>
                            <v-btn v-if="canRemove()" class="mr-2" color="error" @click.prevent="deleteData()">
                              Remover
                            </v-btn>

                          </v-col>

                        </v-row>
                    </v-form>
                </v-container>
            </v-tab-item>

            <v-tab-item>
                <v-container>
                    <v-data-table
                        :headers="headers"
                        :items="items" >
                        <template  v-slot:body="{ items }">
                            <tbody>
                            <tr v-for="item in items" :key="item._id">
                                <td>{{ item.properties.nome }}</td>
                                <td>{{ item.properties.ip_helper }}</td>
                                <td>{{ item.geometry.coordinates[1] }}</td>
                                <td>{{ item.geometry.coordinates[0] }}</td>
                                <td>{{ item.properties.setor }}</td>
                                <td @click="getData(item._id)"><a><v-icon>mdi-account-edit</v-icon></a></td>
                            </tr>
                            </tbody>
                        </template>
                    </v-data-table>
                </v-container>
            </v-tab-item>
        </v-tabs>
        <!--    endregion cadastro helper-->
    </div>
</template>

<script>
    let options =''

    export default {
        name: "CadHelper",
        data(){
            return {
                page : {
                  setor: JSON.parse(sessionStorage.user).user.setor,
                  loading: {
                    isLoading: true,
                    fullPage: true
                  },
                  alert: {
                    show : false,
                    state : "danger",
                    message : "",
                  },
                  abaAtiva: 0,
                },
                tabTitle: "Cadastro Helpers",
                uri:'/helper',
                valid:true,
                singleExpand: false,
                editaSetor: false,
                setor: JSON.parse(sessionStorage.user).user.setor,
                entity:{
                    geometry:{
                        coordinates:[]
                    },
                    properties:{}
                },
                headers: [
                    {
                        sortable: true,
                        text: 'Nome do Helper',
                        value: 'nome'
                    },
                    {
                        sortable: true,
                        text: 'IP Helper',
                        value: 'ipHelper'
                    },
                    {
                        sortable: true,
                        text: 'Latitude',
                        value: 'latitude'
                    },
                    {
                        sortable: true,
                        text: 'Longitude',
                        value: 'longitude'
                    },
                    {
                        sortable: true,
                        text: 'Setor',
                        value: 'setor'
                    },
                    {
                        sortable: true,
                        text: 'Editar',
                        value: 'editar'
                    }
                ],

                items: [],

                //HELPER
                fieldRequired: [
                  v => !!v || 'Campo Obrigatório',
                ],
                coordinatesRules: [
                    h => !!h || 'As coordenadas do Helper são obrigatório',
                ],
                //FIM HELPER
            }
        },

        methods:{

          checkExist() {
            for (let item of this.items) {
              if (this.entity._id === item._id) {
                return true
              }
            }
            return false
          },

          getData(id) {
            this.page.loading.isLoading = true;
            for (let item of this.items) {
              if (item._id === id) {
                Object.keys(item).forEach(key => {
                  this.entity[key] = item[key];
                });
                this.page.abaAtiva = 0 //ABA DE CADASTRO
              }
            }
            this.page.loading.isLoading = false;
          },

          listData() {
            this.page.loading.isLoading = true;
            this.axios.get(this.uri)
                .then(response => {
                  this.items = [...response.data];
                  this.page.loading.isLoading = false;
                  this.page.abaAtiva = 0;
                });
          },

          addData() {

            if (!this.$refs.form.validate()) {
              return false;
            }
            this.page.loading.isLoading = true;
            //VERIFICAR SE JÁ NÃO EXISTE email DEFINIDO

            this.axios.post(this.uri + '/add', this.entity)
                .then((res) => {
                  this.showSuccess( res.data.message );
                  this.page.loading.isLoading = false;
                  this.listData();
                }).catch(err => {
                  this.showError( err.response.data );
                  this.page.loading.isLoading = false;
                }
            );

          },

          editData() {
            if (!this.$refs.form.validate()) {
              return false;
            }
            this.page.loading.isLoading = true;
            this.axios.post(this.uri + `/update/${this.entity._id}`, this.entity)
                .then((res) => {
                  this.showSuccess( res.data.message );
                  this.page.loading.isLoading = false;
                  this.listData();
                }).catch((err) => {
                  this.showError( err.data );
                  this.page.loading.isLoading = false;
                });
          },

          canRemove() {
            return (
                this.checkExist() &&
                (this.entity._id !== "") &&
                (["Super", "SysAdmin"].includes(JSON.parse(sessionStorage.getItem('user')).user.permissao))
            );
          },

          deleteData() {
            if (this.canRemove()) {
              this.$confirm("Tem certeza que deseja remover o helper?","Atenção", "question", {
                confirmButtonText: 'Sim',
                cancelButtonText: 'Não',
              }).then((returns) => {
                    if(returns) {
                      this.page.loading.isLoading = true;
                      this.axios.delete(this.uri + `/delete/${this.entity._id}`)
                          .then((res) => {
                            this.listData();
                            this.clearForm();
                            this.showSuccess( res.data.message );
                            this.page.loading.isLoading = false;
                          }).catch((err) => {
                        this.showError( err.data );
                        this.page.loading.isLoading = false;
                      });
                    }
                  }
              ).catch(() => {
                this.showSuccess( "Operação cancelada!" )
              });

            }
          },


          showSuccess( message ){
            this.page.alert = {
              show: true,
              state: "success",
              message: message,
            }
            this.$fire({
              title: "Sucesso",
              text: this.page.alert.message,
              type: this.page.alert.state
            })
          },

          showError( message ){
            this.page.alert = {
              show: true,
              state: "error",
              message: message,
            }
            this.$fire({
              title: "Erro",
              text: this.page.alert.message,
              type: this.page.alert.state
            })
          },

          clearForm(){
            this.$refs.form.reset();
            this.entity = {
              geometry:{
                coordinates:[]
              },
              properties:{}
            };
            this.listData();
          },
        },
        mounted(){
            this.axios.defaults.headers.common['Authorization'] = 'Bearer '+ JSON.parse(sessionStorage.getItem('user')).token;
            this.entity.setor = this.page.setor;
            this.listData();
            this.page.loading.isLoading = false;
        }
    }
</script>

<style scoped>
    .listaCad{
        margin-top: 0px !important;
    }
    .formCad{
        margin-bottom: 0px!important;
    }

</style>
