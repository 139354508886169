<style lang="scss">
  tbody tr {
    cursor: pointer;
  }
  i.v-icon.mdi-police-badge {
    color: blue;
  }
  i.v-icon.mdi-medical-bag {
    color: green;
  }
  i.v-icon.mdi-fire {
    color: red;
  }
</style>
<template>
  <v-container>
    <v-row justify="center">
      <loading-overlay :active="page.loading.isLoading" :is-full-page="page.loading.fullPage"/>
      <v-col cols="12" md="4" sm="4" xs="12">
        <v-card>
          <v-toolbar dark>
            <v-toolbar-title>Chamar Central</v-toolbar-title>
          </v-toolbar>
          <v-card-text>
            <v-dialog transition="dialog-top-transition" max-width="265">
              <template v-slot:activator="{ on, attrs }">
                <v-simple-table>
                  <template v-slot:default>
                    <thead>
                      <tr>
                        <th colspan="2" class="text-center">
                          EMERGÊNCIAS
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr class="text-left" v-for="item in desserts" :key="item.context">
                        <td v-bind="attrs" v-on="on" @click="call(item.context, item.icone)">
                          <v-icon>{{ item.icone }}</v-icon>
                          {{ item.context }}
                        </td>
                      </tr>
                      <tr class="text-left ml-5" style="margin-left:10%;">
                        <td dark @click.stop="dialog = true">
                          <v-icon>mdi mdi-keyboard</v-icon> Mensagem
                          <v-dialog v-model="dialog" max-width="380">
                            <v-card>
                              <v-form ref="form" v-model="valid" lazy-validation>
                                <v-card-title class="text-h6">
                                  Enviar Mensagem
                                </v-card-title>
                                <v-card-text>
                                  <v-textarea v-model="mensagem" outlined label="Digite a mensagem..." :value="(mensagem)" :rules="['Required']"></v-textarea>
                                </v-card-text>
                                <v-card-actions>
                                  <v-spacer></v-spacer>
                                  <v-btn color="danger" @click="dialog=false">
                                    Fechar
                                  </v-btn>
                                  <v-btn color="primary" v-bind="attrs" v-on="on" @click="call(null, null)">
                                    Enviar
                                  </v-btn>
                                </v-card-actions>
                              </v-form>
                            </v-card>
                          </v-dialog>
                        </td>
                      </tr>
                    </tbody>
                  </template>
                </v-simple-table>
              </template>
              <template v-slot:default="dialog">
                <v-card>
                  <v-toolbar
                    color="dark"
                    dark
                  >Chamada da central</v-toolbar>
                  <v-card-text>
                    <div class="pa-2">Central acionada com sucesso!</div>
                  </v-card-text>
                  <v-card-actions class="justify-end">
                    <v-btn
                      text
                      color="error"
                      @click="dialog.value = false"
                    >Fechar</v-btn>
                  </v-card-actions>
                </v-card>
              </template>
            </v-dialog>
            <label>Acione o botão para acionar a central em caso de emergência.</label>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>

let area = 1 // raio de 1km em relação ao helper do setor que o professor estiver associado

export default {
  name: "Professor",
  components: {
  },
  data() {
    return {
      helper: null,
      dialog: false,
      mensagem: null,
      page : {
        loading: {
          isLoading: true,
          fullPage: true
        },
      },
      uri: '/helper',
      helpers: false,
      setor: JSON.parse(sessionStorage.user).user.setor,
      desserts: [
        {
          icone: 'mdi-police-badge',
          context: 'Policial'
        },
        {
          icone: 'mdi-medical-bag',
          context: 'Médica'
        },
        {
          icone: 'mdi-fire',
          context: 'Bombeiro'
        }
      ],
      gettingLocation: false,
      location: false,
      callEnabled: false
    }
  },
  mounted() {
    if(!("geolocation" in navigator)) {
      this.errorStr = 'Geolocation is not available.';
      return;
    }

    this.gettingLocation = true;
    
    navigator.geolocation.getCurrentPosition(pos => {
      this.gettingLocation = false;
      this.location = pos;
      this.listDataHelpers();
    }, err => {
      this.gettingLocation = false;
      this.errorStr = err.message;
    })
  },
  methods: {
    call(msg, icon) {
      this.dialog=false
      this.$socket.emit("ALERT_TEACHER", {
        user: JSON.parse(sessionStorage.user).user,
        datetime: new Date(),
        requisicao: msg,
        icon: icon,
        msg: this.mensagem,
        http: this.location,
        setor: JSON.parse(sessionStorage.user).user.setor,
        nome: JSON.parse(sessionStorage.user).user.nome,
        telefone: JSON.parse(sessionStorage.user).user.telefone,
        email: JSON.parse(sessionStorage.user).user.email,
        helper: this.helper,
        status: 0
      });
      this.mensagem = null
    },
    listDataHelpers() {
      this.page.loading.isLoading = true;
      this.axios.get(this.uri)
          .then(response => {
            this.helpers = [...response.data];
            this.page.loading.isLoading = false;
            this.checkProximidade(this.helpers) // verifica se a posição permite a chamada de alertas
          });
    },
    checkProximidade(helpers) {
      for (let helper of helpers) {
      //   if(this.setor == helper.properties.setor) { // verifica se é do mesmo contrato
          if(helper.geometry.coordinates[0] != 0 && helper.geometry.coordinates[1] != 0) {

              var distancia = (this.getDistanceFromLatLonInKm(helper.geometry.coordinates[1], helper.geometry.coordinates[0], this.location.coords.latitude, this.location.coords.longitude).toFixed(2));

              if(distancia<=area) {
                this.callEnabled=true
                this.helper = helper.properties.nome
              } 
      //   }
          }
      }
    },
    getDistanceFromLatLonInKm(lat1,lon1,lat2,lon2) {
      var R = 6371; // Radius of the earth in km
      var dLat = this.deg2rad(lat2-lat1);  // deg2rad below
      var dLon = this.deg2rad(lon2-lon1); 
      var a = 
        Math.sin(dLat/2) * Math.sin(dLat/2) +
        Math.cos(this.deg2rad(lat1)) * Math.cos(this.deg2rad(lat2)) * 
        Math.sin(dLon/2) * Math.sin(dLon/2)
        ; 
      var c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1-a)); 
      var d = R * c; // Distance in km
      return d;
    },
    deg2rad(deg) {
      return deg * (Math.PI/180)
    }
  }
};
</script>

<style>
</style>