<template>
    <v-container
            fill-height
            fluid
    >
        <v-form>
            <v-container class="py-0">
                <v-row>
                    <v-col cols="6" >
                        <v-text-field
                                label="Nome Drone"
                                v-model="nome_drone"
                        />
                    </v-col>
                    <v-spacer></v-spacer>
                    <v-col cols="6">
                        <v-text-field
                                label="Localidade"
                                :value="JSON.parse(sessionStorage.user).user.setor"
                                disabled
                        />
                    </v-col>
                </v-row>
                <v-row>
                    <v-col cols="12" >
                        <v-text-field
                                label="IP Central Controle"
                                v-model="ip_controle"
                        />
                    </v-col>

                        <v-col cols="6" >
                            <v-text-field
                                    label="Altitude"
                                    v-model="altitude"
                            />
                        </v-col>
                        <v-col cols="6" >
                            <v-text-field
                                    label="Velocidade"
                                    v-model="velocidade"
                            />
                        </v-col>

                    <v-col cols="8" >
                        <v-text-field
                                label="Longitude"
                                v-model="longitude"
                        />
                    </v-col>

                    <v-col cols="8">
                        <v-text-field
                                label="Latitude"
                                v-model="latitude"
                        />
                    </v-col>

                    <v-col
                            cols="12"
                            class="text-right"
                    >
                        <v-btn color="info" type="submit">
                            Update Profile
                        </v-btn>
                    </v-col>
                </v-row>
            </v-container>
        </v-form>
    </v-container>
</template>

<script>
    //import MaterialCard from "./MaterialCard";
    export default {
        name: "MissaoDrone",
        components:{
            // MaterialCard,
        },
        data(){
            return{
                nome_drone: '',
                altitude:'',
                velocidade: '',
                ip_controle: '',
                acao: '1',
                raio_tomada: 1,
                param: 0,
                gsd: 1.238,
                execucao: false,
                latitude:'',
                longitude:'',
            }
        },
        created(){
            //console.log("mounted");
            //console.log(this.$route.query);
            this.nome_drone = this.$route.query.nome_drone || this.nome_drone
            this.altitude = this.$route.query.altitude || this.altitude
            this.velocidade = this.$route.query.velocidade || this.velocidade
            this.ip_controle = this.$route.query.ip_controle || this.ip_controle
            this.acao = this.$route.query.acao || this.acao
            this.raio_tomada = this.$route.query.raio_tomada || this.raio_tomada
            this.param = this.$route.query.param || this.param
            this.gsd = this.$route.query.gsd || this.gsd
            this.execucao = this.$route.query.execucao || this.execucao
            this.latitude = this.$route.query.latitude || this.latitude
            this.longitude = this.$route.query.longitude || this.longitude

        }
    }
</script>

<style scoped>

</style>
