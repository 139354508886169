<template>
    <v-container fluid>
        <v-tabs grow
                background-color="grey darken-1 accent-4" dark>
            <v-tab v-for="i in tabs" :key="i" :href="`#tab-${i}`" >
                {{i}}
            </v-tab>
            <v-tab-item v-for="i in tabs" :key="i" :value="'tab-' + i" >
                <v-card flat tile>
                    <v-card-text v-if="i==tabs[0]"><batimento-helper></batimento-helper></v-card-text>
                    <v-card-text v-if="i==tabs[1]"><conexao-helper></conexao-helper></v-card-text>
                    <v-card-text v-if="i==tabs[2]"><status-portas></status-portas></v-card-text>
                    <v-card-text v-if="i==tabs[3]"><info-helper></info-helper> </v-card-text>
                </v-card>
            </v-tab-item>
        </v-tabs>
    </v-container>
</template>

<script>
    import BatimentoHelper from "../components/BatimentoHelper"
    import ConexaoHelper from "../components/ConexaoHelper"
    import StatusPortas from "../components/StatusPortas"
    import InfoHelper from "../components/InfoHelper"
    export default {
        nome:'helper',
        components:{
            BatimentoHelper,
            ConexaoHelper,
            StatusPortas,
            InfoHelper,
        },
        data () {
            return {
                prevIcon: false,
                nextIcon: false,
                tabs: [
                    'Lista Helper',
                    'Conexão do Helper',
                    'status',
                    'Informação',
                ],
            }
        },
    }
</script>

<style scoped>

</style>
