<template xmlns:v-slot="http://www.w3.org/1999/XSL/Transform">
  <v-row class="fill-height">
    <v-col>
      <v-sheet height="64">
        <v-toolbar flat color="white">
          <v-btn outlined class="mr-4" @click="setToday">Hoje</v-btn>
          <v-btn fab text small @click="prev">
            <v-icon small>mdi-chevron-left</v-icon>
          </v-btn>
          <v-btn fab text small @click="next">
            <v-icon small>mdi-chevron-right</v-icon>
          </v-btn>
          <v-toolbar-title>{{ title }}</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-menu bottom right>
            <template v-slot:activator="{ on }">
              <v-btn outlined v-on="on">
                <span>{{ typeToLabel[type] }}</span>
                <v-icon right>mdi-menu-down</v-icon>
              </v-btn>
            </template>
            <v-list>
              <v-list-item @click="type = 'day'">
                <v-list-item-title>Dia</v-list-item-title>
              </v-list-item>
              <v-list-item @click="type = 'week'">
                <v-list-item-title>Semana</v-list-item-title>
              </v-list-item>
              <v-list-item @click="type = 'month'">
                <v-list-item-title>Mês</v-list-item-title>
              </v-list-item>
              <v-list-item @click="type = '4day'">
                <v-list-item-title>4 Dias</v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
        </v-toolbar>
      </v-sheet>
      <v-sheet height="600">
        <v-calendar
          ref="calendar"
          locale="pt-br"
          v-model="focus"
          :events="events"
          :event-color="getEventColor"
          :event-margin-bottom="3"
          :now="today"
          :type="type"
          @click:event="showEvent"
          @click:more="viewDay"
          @click:date="viewDay"
          @change="updateRange"
        ></v-calendar>
        <v-menu
          v-model="selectedOpen"
          :close-on-content-click="false"
          :activator="selectedElement"
          offset-x
        >
          <v-card color="grey lighten-4" min-width="350px" flat>
            <v-toolbar :color="selectedEvent.color" dark>
              <v-btn icon @click="deleteAgenda(selectedEvent)">
                <v-icon>mdi-delete</v-icon>
              </v-btn>
              <v-btn icon @click="armed(selectedEvent)">
                <v-icon>mdi-pencil</v-icon>
              </v-btn>
              <v-dialog v-model="dialog1" persistent>
                <v-card>
                  <v-col>
                    <v-container>
                      <v-form>
                        <v-row>
                          <!-- selecionar todos os audios ou um bloco ou individual -->
                          <v-col cols="12" md="6">
                            <v-card>
                              <v-responsive>
                                <v-card-text>
                                  <v-checkbox
                                    v-model="rd2_tipoEvento"
                                    label="selecionar todas os audios"
                                  ></v-checkbox>
                                  <v-list>
                                    <v-list-item-group v-model="list_mensagens" multiple>
                                      <template v-for="(item, i) in mensagens">
                                        <v-list-item :key="`item-${i}`" :value="item">
                                          <template v-slot:default="{ active, toggle }">
                                            <v-list-item-action>
                                              <v-checkbox
                                                :input-value="active"
                                                :true-value="item"
                                                color="grey darken-2"
                                                @click="toggle"
                                              ></v-checkbox>
                                            </v-list-item-action>

                                            <v-list-item-content>
                                              <v-list-item-title v-text="item"></v-list-item-title>
                                            </v-list-item-content>
                                          </template>
                                        </v-list-item>
                                      </template>
                                    </v-list-item-group>
                                  </v-list>
                                </v-card-text>
                              </v-responsive>
                            </v-card>
                          </v-col>

                          <v-col cols="12" md="4">
                            <!-- Escolher como sera o modo de repetição -->
                            <v-radio-group v-model="rd_tipoEvento">
                              <v-radio
                                v-for="(n, i) in lblAgendamento"
                                :key="i"
                                :label="`${n}`"
                                :value="agendamento[i]"
                              ></v-radio>
                            </v-radio-group>

                            <v-card v-if="rd_tipoEvento== 'Único'">
                              <v-card-subtitle>
                                Selecionar data inicial e final para tocar as mensagens.
                                <br />O agendamento será deletado depois da data final.
                              </v-card-subtitle>
                              <v-card-text>
                                <!--    data final -->
                                <v-text-field
                                  type="date"
                                  label="Início"
                                  v-model="data1"
                                  outlined
                                  required
                                ></v-text-field>

                                <!--    data final -->
                                <v-text-field
                                  type="date"
                                  label="Fim"
                                  v-model="data2"
                                  outlined
                                  required
                                ></v-text-field>
                              </v-card-text>
                            </v-card>

                            <v-card class="mt-4">
                              <v-card-subtitle>
                                Selecionar o intervalo em minutos no qual as mensagens selecionadas irão tocar(minimo de 15 minutos)
                                <br />ex: a cada 30 minutos
                              </v-card-subtitle>
                              <v-card-text>
                                <!-- horario inicial -->
                                <v-menu
                                  ref="menu"
                                  v-model="menu1"
                                  :close-on-content-click="false"
                                  :nudge-right="40"
                                  transition="scale-transition"
                                  offset-y
                                  rows="1"
                                  max-width="290px"
                                  min-width="290px"
                                >
                                  <template v-slot:activator="{ on }">
                                    <v-text-field
                                      v-model="time1"
                                      v-on="on"
                                      label="Início"
                                      prepend-icon="mdi-clock-outline"
                                    ></v-text-field>
                                  </template>
                                  <v-time-picker
                                    v-if="menu1"
                                    format="24hr"
                                    v-model="time1"
                                    @click:minute="$refs.menu.save(time1)"
                                  ></v-time-picker>
                                </v-menu>

                                <!-- horario Final -->
                                <v-menu
                                  ref="menu"
                                  rows="2"
                                  v-model="menu2"
                                  :close-on-content-click="false"
                                  :nudge-right="40"
                                  :return-value.sync="time2"
                                  transition="scale-transition"
                                  offset-y
                                  max-width="290px"
                                  min-width="290px"
                                >
                                  <template v-slot:activator="{ on }">
                                    <v-text-field
                                      v-model="time2"
                                      label="Fim"
                                      v-on="on"
                                      prepend-icon="mdi-clock-outline"
                                    ></v-text-field>
                                  </template>
                                  <v-time-picker
                                    format="24hr"
                                    v-if="menu2"
                                    v-model="time2"
                                    @click:minute="$refs.menu.save(time2)"
                                  ></v-time-picker>
                                </v-menu>
                              </v-card-text>
                            </v-card>

                            <v-card class="mt-4">
                              <v-card-text>
                                <v-card-subtitle>
                                  Selecionar o intervalo em minutos no qual as mensagens selecionadas irão tocar
                                  <br />ex: a cada 30 minutos(minimo de 15 minutos)
                                </v-card-subtitle>

                                <!--    intervalo em minutos -->
                                <v-text-field
                                  type="number"
                                  label="Intervalo entre mensagens (minutos)"
                                  v-model="min"
                                  outlined
                                  required
                                ></v-text-field>
                              </v-card-text>
                            </v-card>

                            <v-card class="mt-4">
                              <v-card-text>
                                <v-card-subtitle class="red--text" v-show="!disable_diasSemana">
                                  Escolher quais dias da semana as mensagens devem tocar
                                  <br />(Escolha pelo menos um dia)
                                </v-card-subtitle>
                                <v-checkbox
                                  :rules="marcador"
                                  ref="diasSemana"
                                  v-for="s in semana"
                                  :key="s"
                                  v-model="cb_diasSemana"
                                  :label="`${s}`"
                                  :value="s"
                                  color="red"
                                  hide-details
                                  :disabled="disable_diasSemana"
                                ></v-checkbox>
                              </v-card-text>
                            </v-card>
                          </v-col>
                        </v-row>

                        <!--    Cor do evento -->
                        <v-row>
                          <v-col cols="12" xs="2">
                            <v-radio-group v-model="rd_tipoEvento" row>
                              <v-select
                                v-model="cor"
                                :items="cores"
                                label="ESCOLHA A MENSAGEM"
                                required
                              ></v-select>
                            </v-radio-group>
                          </v-col>
                        </v-row>

                        <!--    botoes -->
                        <v-row>
                          <v-btn
                            class="ml-2 mt-4"
                            small
                            depressed
                            color="primary"
                            @click.prevent="editAgenda(selectedEvent)"
                          >Agendar</v-btn>
                          <v-btn
                            class="ml-2 mt-4"
                            small
                            depressed
                            color="primary"
                            @click="tocarAudio"
                          >Reproduzir no Helper</v-btn>
                           <v-btn
                            class="ml-2 mt-4"
                            small
                            depressed
                            color="primary"
                            @click.prevent="cancelar()"
                          >Cancelar</v-btn>
                        </v-row>
                      </v-form>
                    </v-container>
                  </v-col>
                </v-card>
              </v-dialog>
              <v-toolbar-title v-html="selectedEvent.name"></v-toolbar-title>
              <v-spacer></v-spacer>
            </v-toolbar>
            <v-card-text>
              <span v-html="selectedEvent.details"></span>
            </v-card-text>
            <v-card-actions>
              <v-btn icon color="primary" @click="selectedOpen = false">
                <v-icon>mdi-close</v-icon>
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-menu>
      </v-sheet>
    </v-col>
  </v-row>
</template>

<script>
import {
  parseISO,
  format,
  isAfter,
  addWeeks,
  isSameDay,
  addDays,
} from "date-fns";
import bus from "src/bus";
import { getDay } from "date-fns/esm";

export default {
  name: "CalendarioAgenda",
  props: {
    events: Array,
    helperSelecionado: Object,
  },

  data: () => ({
    today: "",
    focus: "",
    type: "month",
    typeToLabel: {
      month: "Mês",
      week: "Semana",
      day: "Dia",
      "4day": "4 Dias",
    },
    start: null,
    end: null,
    selectedEvent: {},
    selectedElement: null,
    selectedOpen: false,
    dialog1: false,
    disable_diasSemana: false,
    //tabs: ["Agendar", "Ver Agenda"],
    // helpers
    selectMsg: [],
    mensagens: [],
    list_mensagens: [],
    //form data
    rd_tipoEvento: 1,
    rd2_tipoEvento: false,
    cb_diasSemana: [],
    min: "30",
    landscape: true,
    time1: "07:00",
    time2: "21:00",
    data1: "",
    data2: "",
    menu1: false,
    menu2: false,
    lblAgendamento: [
      "Definir período personalizado para agendar mensagens",
      "Agendar mensagens para tocar diariamente",
      "Agendar mensagens para tocar semanalmente, nos dias determinados",
    ],
    agendamento: ["Único", "Diário", "Semanal"],
    semana: [
      "segunda",
      "terça",
      "quarta",
      "quinta",
      "sexta",
      "sábado",
      "domingo",
    ],
    cores: ["azul", "vermelho", "laranja", "verde", "amarelo", "cinza"],
    colors: ["blue", "red", "orange", "green", "yellow", "grey"],
    cor: "azul",
    marcador: [(v) => !!v || "Nome de Usuário Obrigatório "],
  }),

  watch: {
    rd_tipoEvento(e) {
      //console.log(e)
      switch (e) {
        case "Único":
          this.disable_diasSemana = false;
          break;
        case "Diário":
          this.cb_diasSemana = [];
          for (var key in this.semana) {
            this.cb_diasSemana.push(this.semana[key]);
          }
          this.disable_diasSemana = true;
          break;
        case "Semanal":
          this.cb_diasSemana = [];
          this.disable_diasSemana = false;
          break;
      }
    },
    rd2_tipoEvento(e) {
      this.list_mensagens = [];
      if (e == true) {
        for (var key in this.mensagens) {
          this.list_mensagens.push(this.mensagens[key]);
        }
      }
      this.selectMsg = this.list_mensagens;
    },
    time1(val, oldVal) {
      if ((val < "07:00" || val > "21:00") && val != oldVal) {
        this.$refs.menu.save(this.time1);
        this.dialog1 = true;
      }
    },
    time2(val, oldVal) {
      if ((val < "07:00" || val > "21:00") && val != oldVal) {
        this.$refs.menu.save(this.time2);
        this.dialog1 = true;
      }
    },
    min(val, oldval) {
      if (val < "15" && val != oldval) {
        this.$refs.menu.save(this.min);
        this.dialog3 = true;
      }
    },
    events: {
      // This will let Vue know to look inside the array
      deep: true,
      handler(val, oldVal) {
        if (val != oldVal && val.length > 0) {
          val.forEach((e) => {
            //console.log(e)
            //console.log(e.details.type)
            switch (e.details.type) {
                //DIÁRIO
              case "Diário":
                let dia = e.start.split(" ")[0];
                dia = parseISO(dia);
                let hoje = new Date();
                let calRecente = null;
                let final = null;

                if (isAfter(hoje, dia)) calRecente = hoje;
                else calRecente = dia;

                final = addWeeks(calRecente, 8);
                calRecente = addDays(calRecente, 1);
                for (calRecente; !isSameDay(final, calRecente); calRecente = addDays(calRecente, 1)) {
                  let replica = {};
                  Object.assign(replica, e);

                  replica.start =
                      format(calRecente, "yyyy-MM-dd ") + e.start.split(" ")[1];
                  replica.end =
                      format(calRecente, "yyyy-MM-dd ") + e.end.split(" ")[1];

                  bus.$emit("PUSH_TO_CALENDAR", replica);
                }
                break;

                // SEMANAL
              case "Semanal":
              case "Único":
                let agendado = parseISO(e.start.split(" ")[0]); //CONTÉM A DATA INICIAL DO AGENDAMENTO
                let whoje = new Date();
                let wcalRecente = null;
                let wfinal = null;
                let wDaysExt = e.details.days; // CONTÉM OS DIAS DA SEMANA QUE DEVEM TOCAR
                let hstart = e.start.split(" ")[1];
                let hend = e.end.split(" ")[1];

                wcalRecente = (!isAfter(whoje, agendado)) ? whoje : agendado; //SE NÃO FOR DEPOIS, MARCAR HOJE
                wfinal = addWeeks(wcalRecente, 8);
                wcalRecente = addDays(wcalRecente, 1);

                // let week = ["Mon","Tue","Wed", "Thu", "Fri", "Sat", "Sun"];
                let semana = ["segunda","terça","quarta","quinta","sexta","sábado","domingo"];

                //VARRER OS DIAS
                for (wcalRecente; !isSameDay(wfinal, wcalRecente); wcalRecente = addDays(wcalRecente, 1)) {

                  //PEGAR O DIA DA SEMANA
                  let wdayToday = getDay(wcalRecente); // contem 1, 2, 3, 4, 5, 6 ou 0 (nesta ordem de segunda a domingo)
                  wdayToday = (wdayToday == 0) ? 6 : wdayToday;//se for domingo
                  //PEGAR O ÍNDICE
                  // let wdayIndex = week.indexOf(wdayToday); // contem o index desse numero acima na tabela week
                  //PEGAR O ÍNDICE
                  let wday = semana[wdayToday-1]; // contem o dia por extenso com base no index acima

                  //SE NÃO INCLUI NA CONFIGURAÇÃO DO AGENDAMENTO, CANCELAR
                  if (!wDaysExt.includes(wday)) continue;

                  var replica = {};
                  Object.assign(replica, e);

                  //DEFININDO HORA INÍCIO
                  replica.start = format(wcalRecente, "yyyy-MM-dd ") + hstart;

                  //DEFININDO HORA FIM
                  replica.end = format(wcalRecente, "yyyy-MM-dd ") + hend;

                  bus.$emit("PUSH_TO_CALENDAR", replica);
                }
                break;


              default:
                // no personalizado todos os parametros ja estarçao configurados
                break;
            }
          });
        }
      }
    },

  },

  computed: {
    title() {
      const { start, end } = this;
      if (!start || !end) {
        return "";
      }

      const startMonth = this.monthFormatter(start);
      const endMonth = this.monthFormatter(end);
      const suffixMonth = startMonth === endMonth ? "" : endMonth;

      const startYear = start.year;
      const endYear = end.year;
      const suffixYear = startYear === endYear ? "" : endYear;

      const startDay = start.day;
      const endDay = end.day;

      switch (this.type) {
        case "month":
          return `${startMonth} ${startYear}`;
        case "week":
          return ` ${startDay} a ${endDay} de ${startMonth} de ${startYear}`;
        case "4day":
          return ` ${startDay} a ${endDay-1} de ${startMonth} de ${startYear}`;
        case "day":
          return `${startDay} ${startMonth}  ${startYear}`;
      }
      return "";
    },
    monthFormatter() {
      return this.$refs.calendar.getFormatter({
        timeZone: "UTC",
        month: "long",
      });
    },
  },

  mounted() {
    this.$refs.calendar.checkChange();
    this.cliente = `${this.helperSelecionado.properties.ip_helper}@${this.helperSelecionado.properties.setor}`;

    //zera agenda
    this.configuraSocket();
    this.preencheHoje();

    this.events = [];
    //pede para o Helper a AGENDA
    this.$socket.emit("MSG_HELPER", {
      cliente: this.cliente,
      requisicao: "GET_AGENDA",
      dados: "",
      status: 0
    });

    // pede para o Helper a lista de audios
    this.$socket.emit("MSG_HELPER", {
      cliente: this.cliente,
      requisicao: "LIST_AUDIO",
      dados: "",
      status: 0
    });

  },

  methods: {

    //=============================================
    //================ FUNCTIONS ==================
    //=============================================

    configuraSocket() {

      this.$socket.on('MSG_CLIENTE', async (data) =>{
        switch(data.cliente){
          case 'resposta_list_audio':
            this.mensagens = data.dados.files;
            this.selectMsg = data.dados.files[0];
            break;
        }
      });

    },

    deleteAgenda(selected) {
      this.$socket.emit("MSG_HELPER", {
        cliente: this.cliente,
        requisicao: "DELETE_AGENDA",
        dados: selected._id,
        status: 0,
      });
    },

    tocarAudio() {
      this.selectMsg = this.list_mensagens;
      this.$socket.emit("MSG_HELPER", {
        cliente: this.cliente,
        requisicao: "PLAY_AUDIO",
        dados: this.selectMsg,
        status: 0,
      });
    },


    //=============================================
    //=================== LAYOUT ==================
    //=============================================

    armed(selected) {
      // console.log(selected._id + 'putin')
      this.dialog1 = true;
    },

    cancelar() {
      this.dialog1 = false;
    },

    pegaCor() {
      let sel = "";
      for (let i in this.cores) {
        if (this.cores[i] === this.cor) {
          sel = this.colors[i];
          break;
        }
      }
      //console.log("color", sel)
      return sel;
    },

    preencheHoje() {
      let date = new Date();
      //this.time1 = this.addZero(date.getHours()) + ':' + this.addZero(date.getMinutes());
      //this.time2 = this.addZero(date.getHours()) + ':' + this.addZero(date.getMinutes());
      this.data1 =
        date.getFullYear() +
        "-" +
        this.addZero(date.getMonth() + 1) +
        "-" +
        this.addZero(date.getDate());
      this.data2 =
        date.getFullYear() +
        "-" +
        this.addZero(date.getMonth() + 1) +
        "-" +
        this.addZero(date.getDate());
      this.rd_tipoEvento = this.agendamento[2];
      this.cb_diasSemana.push(this.semana[date.getDay() - 1]);
    },

    editAgenda(selected) {

      /*
      this.$socket.emit("MSG_HELPER", {
        cliente: this.cliente,
        requisicao: "GET_AGENDA",
        dados: "",
        status: 0
      });
      this.$socket.on("MSG_CLIENTE", e =>{
          if(e.cliente == 'resposta_agenda'){
              console.log(e);
          }
      })
      */
      if(selected._id != undefined){
        this.$socket.emit("MSG_HELPER",  {
          cliente: this.cliente,
          requisicao: "EDIT_AGENDA",
          id: selected._id,
          dados: {
            details: {
              id: selected._id,
              name: `${this.selectMsg}`,
              start: `${this.data1} ${this.time1}`,
              end: `${this.data2} ${this.time2}`,
              interval: `${this.min}`,
              type: `${this.rd_tipoEvento}`,
              days: `${this.cb_diasSemana}`,
              color: this.pegaCor(),
            },
          },
          status: 0,
        });
        this.$socket.on("MSG_CLIENTE", async(data) => {
          if(data.client = 'resposta_edit_agenda'){
            bus.$emit("notification_edit_agenda", {
              color: "info",
              message: "Agenda Alterada com sucesso",
              timeout: 3000
            });
          }
        })

        this.$socket.emit("MSG_HELPER", {
          cliente: this.cliente,
          requisicao: "GET_AGENDA",
          dados: "",
          status: 0
        });
        this.$socket.on("MSG_CLIENTE", e =>{
            if(e.cliente == 'resposta_agenda'){
              this.events.push(e)
              bus.$emit("notification_resposta_agenda", {
                color: "info",
                message: "Agenda Recuperada",
                timeout: 3000
              });
            }
          })

      //console.log('pediu agenda', this.cliente, pedeAgenda)
      }
      this.dialog1 = false;
    },

    addZero(date) {
      return date < 10 ? "0" + date : date;
    },

    viewDay({ date }) {
      this.focus = date;
      this.type = "day";
    },

    getEventColor(event) {
      return event.color;
    },

    setToday() {
      this.focus = this.today;
    },

    prev() {
      this.$refs.calendar.prev();
    },

    next() {
      this.$refs.calendar.next();
    },

    showEvent({ nativeEvent, event }) {
      const open = () => {
        this.selectedEvent = event;
        this.selectedElement = nativeEvent.target;
        setTimeout(() => (this.selectedOpen = true), 10);
      };

      if (this.selectedOpen) {
        this.selectedOpen = false;
        setTimeout(open, 10);
      } else {
        open();
      }

      nativeEvent.stopPropagation();
    },

    updateRange({ start, end }) {
      // You could load events from an outside source (like database) now that we have the start and end dates on the calendar
      this.start = start;
      this.end = end;
    },

    nth(d) {
      return d > 3 && d < 21
        ? "th"
        : ["th", "st", "nd", "rd", "th", "th", "th", "th", "th", "th"][d % 10];
    },

  },
  created() {
    let date = new Date();

    this.today =
      date.getFullYear() +
      "-" +
      this.addZero(date.getMonth() + 1) +
      "-" +
      this.addZero(date.getDate());
    this.focus =
      date.getFullYear() +
      "-" +
      this.addZero(date.getMonth() + 1) +
      "-" +
      this.addZero(date.getDate());

    //this.checkboxGroup.push(this.semana[(date.getDay()-1)]);
  },
};
</script>
<style lang="css" scoped>
</style>
