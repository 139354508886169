<template>
  <div>
    <loading-overlay :active="page.loading.isLoading" :is-full-page="page.loading.fullPage"/>
    <!-- Seleção do Helper -->
    <!-- <v-card tile flat dark><span class="ml-3">Selecione o Totem Helper:</span></v-card> -->
    <v-toolbar flat dark height="80px">
      <v-toolbar-items class="mt-10">
        <v-autocomplete
            label="Selecione o Totem Helper:"
            chips
            v-model="selectHelper"
            :items="listarHelpers"
            color="white"/>
        <v-btn icon @click="getHelpers">
          <v-icon>mdi-refresh</v-icon>
        </v-btn>
      </v-toolbar-items>
    </v-toolbar>

    <v-alert class="ma-3"
             v-if="!page.helper_active"
             outlined
             type="error"
             border="left"
    >Totem Helper não selecionado ou inativo. Por favor, selecione outro equipamento!</v-alert>

    <v-container align="center" v-if="page.helper_active">

      <v-row justify="center">
        <v-col cols="12" lg="8" md="12">
          <v-card>
            <v-toolbar dark>
              <v-toolbar-title>Mensagens</v-toolbar-title>
            </v-toolbar>
            <v-col>
              <v-row>
                <v-col cols="12" md="6">
                  <v-select chips v-model="file" :items="listaAudios.files" color="white"/>
                </v-col>
                <v-col cols="12" md="6">
                  <v-btn class="mt-5" rounded @click="tocarMensagem">
                    <v-icon>mdi-volume-high</v-icon>
                    Reproduzir Mensagem
                  </v-btn>
                </v-col>
              </v-row>
            </v-col>
          </v-card>
        </v-col>

        <v-col cols="12" lg="8" md="12">
          <v-card>
            <v-toolbar dark>
              <v-toolbar-title>Canais</v-toolbar-title>
            </v-toolbar>
            <v-col>
              <v-row>
                <v-col cols="12" md="6" >
                  <v-btn rounded :color="colorExt" @click="openChannel('EXTERNO')">
                    <v-icon>mdi-volume-high</v-icon>
                    Comunicador Ambiente
                  </v-btn>
                </v-col>
                <v-col cols="12" md="6">
                  <v-btn rounded :color="colorUsu" @click="openChannel('USUARIO')">
                    <v-icon>mdi-headset</v-icon>
                    Atendimento Usuário
                  </v-btn>
                </v-col>
              </v-row>
            </v-col>
          </v-card>
        </v-col>

      </v-row>

    </v-container>
  </div>
</template>

<script>
import bus from '@/bus'

export default {
  name: "Atendimento",
  data() {
    return {
      page : {
        loading: {
          isLoading: true,
          fullPage: true
        },
        alert: {
          show : false,
          state : "danger",
          message : "",
        },
        helper_active: false,
      },
      file: '',
      listaAudios: [],
      canalAberto: '',
      helperOcupado: false,
      colorUsu: '',
      colorExt: '',
      selectHelper: '',
      listaHelpers: [],
    }
  },

  watch: {

    selectHelper(e) {

      this.page.helper_active = false;
      this.helperSelecionado = {};
      this.cliente = {};
      this.listaAudios = []
      this.file = ''

      if(e!="") {
        // altera o valor do Helper selecionado
        this.helperSelecionado = this.listaHelpers.filter(u => u.properties.nome === e)[0]
        // monta a ID do cliente
        this.cliente = `${this.helperSelecionado.properties.ip_helper}@${this.helperSelecionado.properties.setor}`
        // abre canal se requerido
        if (this.$route.path.includes('user')) this.openChannel('USUARIO')
        if (this.$route.path.includes('externo')) this.openChannel('EXTERNO')

        // pede para o Helper a lista de audios
        this.$socket.emit('MSG_HELPER', {
          cliente: this.cliente,
          requisicao: 'LIST_AUDIO',
          dados: '',
          status: 0
        })

      }

    },

    listaAudio(e) {
      this.file = this.listaAudio().filter(u => u.files === e)[0]
    },

    canalAberto(e) {
      this.colorExt = ''
      this.colorUsu = ''
      switch (e) {
        case 'USUARIO':
          this.colorUsu = 'green'
          this.colorExt = ''
          break
        case 'EXTERNO':
          this.colorExt = 'green'
          this.colorUsu = ''
          break
      }
    }

  },

  computed: {

    listarHelpers() {
      let helpers = []
      for (let helper of this.listaHelpers) {
        helpers.push(helper.properties.nome)
      }
      return helpers
    },

  },

  mounted() {
    // REQUERIDO! Essa linha configura o token nas requisições e deve existir em todas as rotas que usam axios
    this.axios.defaults.headers.common['Authorization'] = 'Bearer ' + JSON.parse(sessionStorage.getItem('user')).token;
    // verifica se a rota possui o parametro 'helper' (se foi clicado no helper a partir do mapa)
    // if (typeof this.$route.params.helper !== "undefined") this.selectHelper = this.$route.params.helper;
    // pega helper na DB
    this.getHelpers()
    // configura socket
    this.socketConfig()

    //RETIRAR ONLOADING
    this.page.loading.isLoading = false;
  },

  methods: {

    //=============================================
    //================ FUNCTIONS ==================
    //=============================================

    socketConfig() {

      bus.$on('LIST_AUDIO', s => {
        this.listaAudios = []
        this.listaAudios = s

        this.file = s.files[0]
        if (typeof this.$route.params.msg !== 'undefined') {
          this.file = this.$route.params.msg
        }
        this.page.helper_active = true
      })

      bus.$on("PLAY_AUDIO", s => this.notify(s, 'green'))

      bus.$on('ABRIR_USUARIO_AMBIENTE', s => this.notify(s, 'green'))

      bus.$on('ABRIR_USUARIO_VOIP', s => this.notify(s, 'green'))

      bus.$on("FECHAR_CANAIS", s => this.notify(s, 'green'))

      this.$socket.emit('VERSION')

    },

    getHelpers() {
      this.page.loading.isLoading = true;
      this.page.helper_active = false;
      this.axios.get( '/helper' )
          .then(response => {
            let temp = [...response.data]

            //VERIFICAR SE É SUPER, SE FOR, NÃO FILTRA OS HELPERS
            if (JSON.parse(sessionStorage.user).user.permissao.search(/super/gi) === -1) {
              this.listaHelpers = temp.filter(el => {
                return el.properties.setor === JSON.parse(sessionStorage.user).user.setor
              })
            } else {
              // PREENCHE A LISTA COM TODOS OS HELPERS
              this.listaHelpers = temp
            }

            //preenche a lista com os nomes dos Helpers para usar no dropdown
            for (let i in this.listaHelpers) {
              this.listaHelpers[i].text = this.listaHelpers[i].nomeHelper
            }

            // pré-seleciona o primeiro Helper da lista
            if (typeof this.$route.params.helper === "undefined") {
              this.selectHelper = "";
            } else {
              this.selectHelper = this.$route.params.helper;
              // this.page.helper_active = true;
            }
            this.page.loading.isLoading = false;
          })
    },

    tocarMensagem() {
      this.$socket.emit("MSG_HELPER", {
        cliente: this.cliente,
        requisicao: 'PLAY_AUDIO',
        dados: this.file,
        status: 0
      })
    },

    openChannel( type ) {
      let send = {};
      if (this.canalAberto === type) {
        this.canalAberto = ''
        send = {
          requisicao : "FECHAR_VOIP",
          dados : {requisicao: "FECHAR_VOIP"},
        };
      } else {
        this.canalAberto = type
        if(type === 'USUARIO'){
          send = {
            requisicao : "ABRIR_USUARIO_VOIP",
            dados : {requisicao: "ABRIR_USUARIO_VOIP"},
          };
        } else {
          send = {
            requisicao : "ABRIR_AMBIENTE_VOIP",
            dados : {requisicao: "ABRIR_AMBIENTE_VOIP"},
          };

        }
      }
      this.$socket.emit("MSG_HELPER", {
        cliente: this.cliente,
        requisicao: send.requisicao,
        dados: send.dados,
        status: 0
      })
    },

    getPathFile(file) {
      //console.log("file", file)
      return this.listaAudios.server + this.listaAudios.path + "\\" + file
    },

    //=============================================
    //=========== HELPER FUNCTIONS ================
    //=============================================

    notify(message, type) {
      bus.$emit("notification", {
        color: type,
        message: message,
        timeout: 3000,
      })
    }

  },
  beforeDestroy() {
    bus.$off('LIST_AUDIO')
    bus.$off('PLAY_AUDIO')
    bus.$off('ABRIR_USUARIO_AMBIENTE')
    bus.$off('ABRIR_USUARIO_VOIP')
    bus.$off('FECHAR_CANAIS')
    this.$socket.off('VERSION')
  },
}
</script>

<style scoped>

</style>
