<template xmlns:v-slot="http://www.w3.org/1999/XSL/Transform">
  <div>
    <loading-overlay :active="page.loading.isLoading" :is-full-page="page.loading.fullPage"/>
    <!-- Seleção do Helper -->
    <v-toolbar flat dark height="80px">
      <v-toolbar-items class="mt-10">
        <v-autocomplete
            label="Selecione o Totem Helper:"
            chips
            v-model="selectHelper"
            :items="listHelpers"
            color="white"
        />
        <v-btn icon @click="getHelpers">
          <v-icon>mdi-refresh</v-icon>
        </v-btn>
      </v-toolbar-items>
    </v-toolbar>

    <v-alert class="ma-3"
        v-if="!page.helper_active"
        outlined
        type="error"
        border="left"
    >Totem Helper não selecionado ou inativo. Por favor, selecione outro equipamento!</v-alert>

    <!-- Submenu -->
    <v-tabs vertical v-if="page.helper_active">
      <v-tab>
        <v-icon class="ml-0" left>mdi-calendar</v-icon>
        <span class="d-none d-sm-flex">Agendar</span>
        <v-spacer></v-spacer>
      </v-tab>
      <v-tab
          v-if="page.helper_active">
        <v-icon class="ml-0" left>mdi-calendar</v-icon>
        <span class="d-none d-sm-flex">Ver Agenda</span>
        <v-spacer></v-spacer>
      </v-tab>

      <!-- Função agendar -->
      <v-tab-item
          v-if="page.helper_active">
        <v-col>
          <v-container>
            <v-form>

              <!-- Seleção da mensagem / cor -->
              <v-row>

                <!-- Seleção da mensagem -->
                <v-col cols="12" md="6" xs="12">
                  <v-autocomplete v-model="selectMsg" :items="mensagens"
                            label="ESCOLHA A MENSAGEM" required></v-autocomplete>
                </v-col>

                <!--    Cor do evento -->
                <v-col cols="12" md="6" xs="12">
                    <v-autocomplete v-model="cor" :items="cores"
                              label="ESCOLHA A COR" required></v-autocomplete>
                </v-col>
              </v-row>

              <!-- tipo de evento (unico, diário, semanal) -->
              <v-row>
                <v-col>
                  <label>Agendar para:</label>
                  <v-radio-group v-model="rd_tipoEvento" row>
                    <v-radio v-for="(n, i) in lblAgendamento" :key="i"
                             :label="`${n}`" :value="agendamento[i]">
                    </v-radio>
                  </v-radio-group>
                </v-col>
              </v-row>

              <!-- data de evento -->
              <v-row
                  v-show="page.form.period">
                <!--    data de inicio -->
                <v-col cols="12" md="4" xs="12">
                  <v-text-field type="date" label="Data Inicial"
                                v-model="data1"
                                outlined required>
                  </v-text-field>
                </v-col>
                <!--    data final -->
                <v-col cols="12" md="4" xs="12">
                  <v-text-field type="date" label="Data Final"
                                v-model="data2"
                                outlined required>
                  </v-text-field>
                </v-col>

              </v-row>

              <!--    dias da semana -->
              <label class="red--text"
                     v-show="!page.form.diasSemana"
              >Escolha em quais dias da semana a mensagem deve ser reproduzida (Escolha pelo menos um)</label>
              <v-row
                  justify="space-around"
                  class="mb-5"
                  v-show="!page.form.diasSemana">
                <v-checkbox
                    :rules="marcador"
                    ref="diasSemana"
                    v-for="s in semana"
                    :key="s"
                    v-model="cb_diasSemana"
                    :label="`${s}`"
                    :value="s"
                    color="red"
                    hide-details
                    :disabled="page.form.diasSemana"
                ></v-checkbox>

              </v-row>

              <!-- horario -->
              <v-row>

                <!-- horario inicial -->
                <v-col cols="12" md="3" xs="3">
                  <v-menu ref="menu"
                          v-model="menu1"
                          :close-on-content-click="false"
                          :nudge-right="40"
                          transition="scale-transition"
                          offset-y
                          max-width="290px" min-width="290px">
                    <template v-slot:activator="{ on }">
                      <v-text-field v-model="time1" label="Tempo Inicial" v-on="on"
                                    prepend-icon="mdi-clock-outline" readonly>
                      </v-text-field>
                    </template>
                    <v-time-picker
                        v-if="menu1"
                        v-model="time1"
                        @click:minute="$refs.menu.save(time1)"
                        format="24hr"
                        scrollable
                    ></v-time-picker>
                  </v-menu>
                </v-col>

                <!-- horario final-->
                <v-col cols="12" md="3" xs="3">
                  <v-menu ref="menu" rows="2"
                          v-model="menu2"
                          :close-on-content-click="false"
                          :nudge-right="40"
                          transition="scale-transition"
                          offset-y
                          max-width="290px" min-width="290px">
                    <template v-slot:activator="{ on }">
                      <v-text-field v-model="time2" label="Tempo Final" v-on="on"
                                    prepend-icon="mdi-clock-outline" readonly>
                      </v-text-field>
                    </template>
                    <v-time-picker
                        v-if="menu2"
                        v-model="time2"
                        @click:minute="$refs.menu.save(menu2)"
                        format="24hr"
                        scrollable
                    ></v-time-picker>
                  </v-menu>
                </v-col>

                <!-- intervalo em minutos -->
                <v-col cols="12" md="6" xs="6">
                  <template>
                    <v-card
                        flat
                        color="transparent"
                    >
                      <v-card-text>
                        <v-row>
                          Intervalo (minutos)
                          <v-col class="pr-4">
                            <v-slider
                                v-model="min"
                                class="align-center"
                                :max="m_max"
                                :min="m_min"
                                thumb-label="always"
                                hide-details
                            >
                              <template v-slot:thumb-label="{ value }">
                                {{ value }}
                              </template>
                            </v-slider>
                          </v-col>
                        </v-row>
                      </v-card-text>
                    </v-card>
                  </template>
<!--                  <v-text-field type="number" label="Intervalo em Minutos"-->
<!--                                v-model="min"-->
<!--                                rows="3"-->
<!--                                outlined required>-->
<!--                  </v-text-field>-->
                </v-col>

              </v-row>

              <!--    botoes -->
              <v-row>
                <v-btn class="ml-2 mt-4" small depressed color="primary"
                       @click.prevent="enviarAgenda()">
                  Agendar
                </v-btn>
                <v-btn class="ml-2 mt-4" small depressed color="primary"
                       @click="playAudio">
                  Reproduzir no Helper
                </v-btn>
              </v-row>
            </v-form>
          </v-container>
        </v-col>
      </v-tab-item>

      <!-- Função ver-agendar:  -->
      <v-tab-item
          v-if="page.helper_active">
        <!--    componente calendário -->
        <v-col>
          <v-container>

            <agenda-table
                :events="events"
                :cliente="cliente"
            >
            </agenda-table>

          </v-container>
        </v-col>
      </v-tab-item>

    </v-tabs>


    <!-- ################ -->
    <!-- DIALOGO 1 -->
    <v-dialog v-model="dialog1" max-width="500" persistent>
      <v-card>
        <v-card-title class="headline">Lei da perturbação do sossego</v-card-title>
        <v-card-text>
          <h3>A lei estabelece limites das 07:00 até as 22:00 horas</h3><br>
          <strong><h4>Para aceitar o horario abaixo do limite e a acima do limite permitido
            clique em continuar</h4>
          </strong>
        </v-card-text>

        <v-card-actions>

          <v-btn
              color="green darken-1"
              text
              @click="cancelar()"
          >
            Cancela
          </v-btn>
          <v-btn
              color="green darken-1"
              text
              @click="continuar()"
          >
            Continuar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!--  DIALOG 2 -->
    <v-dialog
        v-model="dialog2"
        max-width="500"
        persistent
    >
      <v-card>
        <v-card-title class="headline">Agendar</v-card-title>
        <v-card-text>
          <p>Confirmando, seus dados serão registrados com o agendamento.</p>
        </v-card-text>
        <v-card-actions>
          <v-btn
              color="green darken-1"
              text
              @click="cancelar()"
          >
            Cancela
          </v-btn>
          <v-btn
              color="green darken-1"
              text
              @click="confirmar()"
          >
            Confirmar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>


  </div>
</template>

<script>
import bus from 'src/bus'

import CalendarioAgenda from "../components/CalendarioAgenda"
import AgendaTable from "@/components/AgendaTable";

export default {
  name: "Agenda",
  components: {
    AgendaTable
  },
  data() {
    return {
      page : {
        loading: {
          isLoading: true,
          fullPage: true
        },
        alert: {
          show : false,
          state : "danger",
          message : "",
        },
        helper_active: false,
        form: {
          diasSemana : false,
          period : false,
        },
      },
      tabs: ['Agendar', 'Ver Agenda'],
      // helpers
      cliente: {},
      selectHelper: "",
      helperSelecionado:{},
      selectMsg: "",
      listaHelpers: [],
      mensagens: [],
      // list_mensagens: [],

      //form data
      rd_tipoEvento: '',
      // rd2_tipoEvento: false,
      cb_diasSemana: [],
      m_min: 15,
      m_max: 60,
      min: "30",
      landscape: true,
      time1: "07:00", time2: "21:00",
      data1: "", data2: "",
      menu1: false, menu2: false,

      dialog1: false,
      dialog2: false,

      lblAgendamento: [
        'Todos os dias',
        'Semanalmente, nos dias determinados',
        'Período personalizado'
      ],
      agendamento: ['Diário', 'Semanal','Único'],
      semana: ['segunda', 'terça', 'quarta', 'quinta', 'sexta', 'sábado', 'domingo'],
      week: ["Mon, Tue, Wed, Thu, Fri, Sat, Sun"],
      cores: ['azul', 'vermelho', 'laranja', 'verde', 'amarelo', 'cinza'],
      colors: ['blue', 'red', 'orange', 'green', 'yellow', 'grey'],
      cor: 'azul',
      events: [],
      marcador: [v => !!v || "Nome de Usuário Obrigatório "],
    }
  },

  watch: {
    //TIPO DE EVENTO
    rd_tipoEvento(e) {
      //console.log(e)
      switch (e) {
        case 'Diário':
          this.cb_diasSemana = [];
          for (var key in this.semana) {
            this.cb_diasSemana.push(this.semana[key]);
          }
          this.page.form.diasSemana = true
          this.page.form.period = false
          break
        case 'Semanal':
          this.cb_diasSemana = [];
          this.page.form.diasSemana = false
          this.page.form.period = false
          break
        case 'Único':
          this.page.form.diasSemana = false
          this.page.form.period = true
          break
      }
    },

    //VERIFICAR SE HORA ESTÁ ABAIXO DE 07h00 E ACIMA DE 21h00
    time1(val, oldVal){
      if((val !== oldVal) /*&& (val < "07:00" || val > "22:00") */){
        this.$refs.menu.save(this.time1)
        // this.dialog1 = true
      }
    },

    //VERIFICAR SE HORA ESTÁ ABAIXO DE 07h00 E ACIMA DE 21h00
    time2(val, oldVal){
      if((val !== oldVal) /*&& (val < "07:00" || val > "22:00") */){
        this.$refs.menu.save(this.time2)
        // this.dialog1 = true
      }
    },

    //INTERVALO COM LIMITE MÍNIMO DE 15m
    min(val, oldval){
      if((parseInt(val) < this.m_min)){
        this.min = this.m_min
      } else if((parseInt(val) > this.m_max)){
        this.min = this.m_max
      }
      this.$refs.menu.save(this.min);
    },

    selectHelper(e) {
      this.page.helper_active = false;
      this.helperSelecionado = {};
      this.cliente = {};
      // zera a lista de mensagens
      this.mensagens = []
      //zera agenda
      this.events = []
      this.selectMsg = ""

      if(e!=""){
        // altera o valor do Helper selecionado
        this.helperSelecionado = this.listaHelpers.filter(u => u.properties.nome === e)[0]
        // monta a ID do cliente
        this.cliente = `${this.helperSelecionado.properties.ip_helper}@${this.helperSelecionado.properties.setor}`

        // pede para o Helper a lista de audios
        this.$socket.emit('MSG_HELPER', {
          cliente: this.cliente,
          requisicao: 'LIST_AUDIO',
          dados: '',
          status: 0
        })

        // pede para o Helper a agenda
        this.$socket.emit("MSG_HELPER", {
          cliente: this.cliente,
          requisicao: "GET_AGENDA",
          dados: "",
          status: 0
        });

      }

    }

  },

  computed: {
    listHelpers() {
      let helpers = []
      for (let helper of this.listaHelpers) {
        helpers.push(helper.properties.nome)
      }
      return helpers
    },
  },

  mounted: function () {
    // REQUERIDO! Essa linha configura o token nas requisições e deve existir em todas as rotas que usam axios
    this.axios.defaults.headers.common['Authorization'] = 'Bearer ' + JSON.parse(sessionStorage.getItem('user')).token;
    // verifica se a rota possui o parametro 'helper' (se foi clicado no helper a partir do mapa)
    // if (typeof this.$route.params.helper !== 'undefined') this.selectHelper = this.$route.params.helper
    // pega helper na DB
    this.getHelpers()
    // configura socket
    this.socketConfig()
    // pré-preenche form
    this.preencheHoje()

    //RETIRAR ONLOADING
    this.page.loading.isLoading = false;

  },

  methods: {

    //=============================================
    //=================== LAYOUT ==================
    //=============================================

    pegaCor() {
      let sel = ''
      for (let i in this.cores) {
        if (this.cores[i] === this.cor) {
          sel = this.colors[i]
          break
        }
      }
      //console.log("color", sel)
      return sel
    },

    cancelar() {
      this.dialog1 = false
      this.dialog2 = false
      this.time1 = "07:00"
      this.time2 = "21:00"
    },

    confirmar(){
      this.dialog2 = false
      this.dialog1 = false
    },

    //=============================================
    //================ FUNCTIONS ==================
    //=============================================

    socketConfig() {

      bus.$on('ERRO', s => {
        //console.log(s)
      })

      bus.$on('HELPER_OCUPADO', s => {
        //console.log(s)
      })

      bus.$on('LIST_AUDIO', s => {
        //console.log('list audio: ', s)
        this.mensagens = s.files
        this.selectMsg = s.files[0]
        this.page.helper_active = true
      })

      bus.$on("PLAY_AUDIO", s => this.notify(s, 'green'))

      bus.$on('SET_AGENDA', s => {
        this.events.push(s)
        this.notify('Mensagem agendada com sucesso', 'primary');
      })

      bus.$on('GET_AGENDA', s => {
        this.events = s
        this.notify('Agenda recuperada', 'info');
      })

      this.$socket.emit('VERSION')
    },

    getHelpers() {
      this.page.loading.isLoading = true;
      this.page.helper_active = false;
      this.axios.get('/helper')
          .then(response => {
            let temp = [...response.data];

            //VERIFICAR SE É SUPER, SE FOR, NÃO FILTRA OS HELPERS
            if (JSON.parse(sessionStorage.user).user.permissao.search(/super/gi) === -1) {
              this.listaHelpers = temp.filter(el => {
                return el.properties.setor === JSON.parse(sessionStorage.user).user.setor
              })
            } else {
              // PREENCHE A LISTA COM TODOS OS HELPERS
              this.listaHelpers = temp
            }

            //preenche a lista com os nomes dos Helpers para usar no dropdown
            for (let i in this.listaHelpers) {
              this.listaHelpers[i].text = this.listaHelpers[i].nomeHelper
            }

            // pré-seleciona o primeiro Helper da lista
            if (typeof this.$route.params.helper === "undefined") {
              this.selectHelper = "";
            } else {
              this.selectHelper = this.$route.params.helper;
              // this.page.helper_active = true;
            }
            this.page.loading.isLoading = false;
          })
    },

    enviarAgenda() {
      this.$socket.emit('MSG_HELPER', {
        cliente: this.cliente,
        requisicao: 'SET_AGENDA',
        dados: {
          name: `${this.helperSelecionado.properties.nome}, ${this.selectMsg}`,
          start: `${this.data1} ${this.time1}`,
          end: `${this.data2} ${this.time2}`,
          details: {
            interval: `${this.min}`,
            type: `${this.rd_tipoEvento}`,
            days: `${this.cb_diasSemana}`,
          },
          color: this.pegaCor()
        },
        status: 0
      })
    },

    preencheHoje() {
      let date = new Date();
      this.data1 = date.getFullYear() + '-' + this.addZero(date.getMonth() + 1) + '-' + this.addZero(date.getDate());
      this.data2 = this.data1;
      this.rd_tipoEvento = 'Diário';
    },

    playAudio() {
      this.$socket.emit('MSG_HELPER', {
        cliente: this.cliente,
        requisicao: 'PLAY_AUDIO',
        dados: this.selectMsg,
        status: 0
      })
    },

    //=============================================
    //=========== HELPER FUNCTIONS ================
    //=============================================

    notify(message, type) {
      bus.$emit("notification", {
        color: type,
        message: message,
        timeout: 3000,
      })
    },

    addZero(date) {
      return (date <= 9 ? "0" + date : date)
    },

  },
  beforeDestroy() {
    //console.log('destroy agenda')
    bus.$off("ERRO");
    bus.$off("HELPER_OCUPADO");
    bus.$off("LIST_AUDIO");
    bus.$off("SET_AGENDA");
    // bus.$off("GET_AGENDA");
    bus.$off("DELETE_AGENDA");
    // bus.$off("PUSH_TO_CALENDAR")
    this.$socket.off('VERSION')
  },
}
</script>
<style lang="css" scoped>
</style>

