import Vue from 'vue'
import VueRouter from 'vue-router'
import Configuracoes from "../views/Configuracoes.vue"
import Agenda from '../views/Agenda.vue'
import techelper from '../views/Helper'
import Audio from 'src/views/Audio'
import CadUsuario from '../components/CadUsuario'
import CadHelper from '../components/CadHelper'
import DashBoard from '../layout/DashBoard.vue'
import NoBar from '../layout/NoBar.vue'

import MissaoDrone from "../components/MissaoDrone"
import Atendimento from "../views/Atendimento"
import Professor from "@/views/Professor"
import PageNotFound from '../components/PageNotFound'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Dashboard',
    component: (typeof sessionStorage.user !== "undefined" && JSON.parse(sessionStorage.user).user.permissao === "Teacher" ? NoBar : DashBoard),
    redirect: (typeof sessionStorage.user !== "undefined" && JSON.parse(sessionStorage.user).user.permissao === "Teacher" ? '/professor' : '/map'),
    children: [
      {
        path: '/agenda',
        name: 'Agenda',
        component: Agenda,
        children:[
          { path: '/agenda/helper/:helper' },
          { path: '/agenda/helper/:helper/msg/:msg'},
        ]
      },
      {
        path: '/cadastro/usuario',
        name: 'cadusuario',
        component: CadUsuario,
      },
      {
        path: '/cadastro/helper',
        name: 'cadhelper',
        component: CadHelper,
      },
      {
        path: '/map',
        name: 'Central de Controle',
        children: [
          {
            path: '/map/audio',
            name: 'mensagens',
            component: Audio,
            children:[
              { path:'/map/audio/helper/:helper' }
            ]
          },
          {
            path: '/map/atendimento',
            name:'map_atendimento',
            component: Atendimento,
            children:[
              {path: '/map/atendimento/helper/:helper'},
              {path: '/map/atendimento/helper/:helper/msg/:msg'},
              {path: '/map/atendimento/helper/:helper/user'},
              {path: '/map/atendimento/helper/:helper/externo'},
            ]
          },
          {
            path:'/map/missao',
            name:'configuramissao',
            component: MissaoDrone
          },
          {
            path:'/map/demo',
            name:'demo',
            component: Configuracoes
          }
        ],
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "about" */ '../views/Map.vue'),
        beforeEnter(to, from, next) {
          //SE FOR PROFESSOR, VAI DIRETO PARA PÁGINA DO PROFESSOR
          if(typeof sessionStorage.user !== "undefined" && JSON.parse(sessionStorage.user).user.permissao === "Teacher"){
            //USER TEACHER
            next('/professor');
          }
          next();
        }
      },
      {
        path: '/atendimento',
        name: 'Atendimento',
        component: Atendimento,
        children:[
          {path:'/atendimento/helper/:helper'},
          {path:'/atendimento/helper/:helper/msg/:msg'}
        ]
      },
      {
        path: '/configuracoes',
        name: 'Configurações',
        component:Configuracoes
      },
      {
        path:'/audio',
        name:'Gerenciar Áudios',
        component: Audio,
        children:[
          {path: '/audio/helper/:helper'},
          {path: '/audio/helper/:helper/msg/:msg'},
        ]
      },
      {
        path: '/professor',
        name: 'Professor',
        component:Professor
      },
      {
        path: '/notifications',
        name:'notificacoes',
        component: () => import('../views/Notifications')
      },
      {
        path:'/helpers',
        name:'Helpers',
        component: techelper
      },
    ]
  },
  {
    path: "*",
    component: PageNotFound
  }
]



const router = new VueRouter({
  routes
})

export default router
