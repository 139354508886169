<template v-slot:activator="{ on, attrs }">

  <v-app>
    <Login v-if="!session"></Login>
    <router-view v-else/>

    <div class="text-center">
      <v-dialog
        persistent
        v-model="dialog"
        width="500"
      >
        <v-card>
          <v-div v-for="item in alertas" :key="item.msg">
            <v-card-title class="headline grey lighten-2">
              Emergência {{item.msg}}
            </v-card-title>
            <v-alert>
                <p>
                  {{item.datetime}}<br/>
                  Solicitante: <b>{{item.nome}}</b><br/>
                  Telefone: <b>{{item.telefone}}</b><br/>
                  E-mail: <b>{{item.email}}</b><br/>
                  Helper: <b>{{item.helper}}</b><br/>
                  <b v-if="item.message">Mensagem: <b>{{item.message}}</b></b>
                </p>
            </v-alert>
          </v-div>
          <v-card-actions class="grey lighten-2">
            <v-spacer></v-spacer>
            <v-btn
              color="warning"
              text
              @click="dialog=false"
            >
              Recebido
            </v-btn>
          </v-card-actions>


        </v-card>
      </v-dialog>
    </div>

  </v-app>
</template>

<script>


  import Login from "src/views/Login";
  import bus from "src/bus";

  export default {
    name: 'App',
    components:{
      Login,
      },
    data: () => ({
      session: false,
      sensor: '',
      dialog: false,
      helper: false,
      alertas: [],
      icon: false
    }),
    watch:{
      session(s){

        if (s) {
          this.axios.defaults.headers.common['Authorization'] = 'Bearer ' + JSON.parse(sessionStorage.getItem('user')).token;
          this.getHelpers()
        }
        else this.axios.defaults.headers.common['Authorization']= ''
      }
    },
    methods:{
      fechar(){
        this.dialog = false;
        this.alertas = []
      },
      getHelpers() {
        let api = '/helper';
        this.axios.get(api)
                .then(response => {
                  // preenche lista com todos os dados dos Helpers
                  let listaHelpers = [...response.data]
                  //preenche a lista com os nomes dos Helpers para usar no dropdown
                  for (let i in this.listaHelpers) {
                     listaHelpers[i].text = listaHelpers[i].nomeHelper
                  }
                  this.$listaHelpers = [...listaHelpers]
                  //console.log(this.$listaHelpers)
                }).catch(err=>{})
      },
    },
    mounted(){
      bus.$on('login', (data)=>{
        this.session = data;
      })
      if (typeof sessionStorage.user !== 'undefined') {
        this.session = true;
      }

      if (sessionStorage.user) {
        this.$socket.on('ALERT_TEACHER', s =>{
          if(JSON.parse(sessionStorage.user).user.permissao !== "Teacher") {
            var alerta = {
              datetime: s.datetime,
              nome: s.nome,
              telefone:s.telefone,
              email:s.email,
              icon:s.icon,
              message:s.msg,
              msg:s.requisicao,
              helper:s.helper
            }
            if(!this.dialog) {
              this.alertas = []
            }
            this.dialog = true
            this.alertas.push(alerta)
            // Alerta sonoror
            new Audio("sounds/notify.mp3").play().catch(() => {});
          }
        });
      }

      this.$socket.on('MSG_CLIENTE', s =>{
        //NÃO SEI PORQUE ESSA RESTRIÇÃO (20/10 - LEONARDO)
        //se não tem o nome da central, e não for Super, não executa
        let user;
        user = JSON.parse(sessionStorage.getItem('user')).user;

        // envia o dado recebido via bus
        bus.$emit(`${s.requisicao}`, s.dados)

        //SE NÃO É SUPER E NÃO ESTÁ NO MESMO SETOR DO TOTEM, NÃO ENVIAR ALERTA
        if((user.permissao !== "Super") && !s.cliente.includes(user.setor)) return;

        // se a requisição é relativa ao status do Helper executa lógica global (ALERTA)
        if(s.requisicao !== 'HELPER_STATUS') return
        // se a mensagem é repetida, não executa lógica
        if(s.dados === this.sensor) return
        // pega o helper
        let nomeHelper = this.$listaHelpers.filter(h => h.properties.ip_helper === s.cliente.split('@')[0])[0]
        // e salva só nome
        nomeHelper = nomeHelper.properties.nome
        // pega os dados recebidos
        this.sensor = s.dados
        // verifica o evento
        if (s.dados.search(/trote|chamando/gi) !== -1) {
          // e gera notificação no bus
          bus.$emit("notification",{
            color:'warning',
            url: s.http,
            message: s.dados + '\r\n' + nomeHelper + '\r\n',
            timeout:0,
          })
        }
        else if (s.dados.search(/vandalismo|porta|umidade|falha_energia/gi) !== -1) {
          bus.$emit("notification",{
            color:'error',
            message: s.dados + '\r\n' + nomeHelper + '\r\n',
            url: s.http,
            timeout:0,
          })
        }
        else if (s.dados.search(/atendendo/gi) !== -1) {
          bus.$emit("notification", {
            color:'warning',
            url: s.http,
            message: s.dados + '\r\n' + nomeHelper + '\r\n', 
            timeout:0,
          })
        }
      })
    },
    beforeDestroy() {
      //console.log('before destory')
      this.$socket.off('ALERT_TEACHER')
      this.$socket.off('MSG_CLIENTE')
      bus.$off('login');
    }
  };
</script>
