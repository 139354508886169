<template xmlns:v-slot="http://www.w3.org/1999/XSL/Transform">
    <v-card>
        <v-card-title>
            Portas Helper
            <v-spacer></v-spacer>
            <v-text-field
                    v-model="search"
                    append-icon="mdi-search"
                    label="Search"
                    single-line
                    hide-details
            ></v-text-field>
        </v-card-title>
        <v-data-table
                :headers="headers"
                :items="desserts"
                :icon="desserts.connect"
                :search="search"
        >
            
            <template v-slot:item.status="{ item }" >
                <v-chip :color="getStatus(item.status)" dark>{{ item.status }}</v-chip>
            </template>


        </v-data-table>

    </v-card>
</template>

<script>
    export default {
        name: "InfoHelper",
        data () {
            return {
                search: '',
                icon: "mdi-refresh",
                headers: [
                    {
                        text: 'Nome Helper',
                        align: 'left',
                        sortable: false,
                        value: 'name',
                    },
                    { text: 'Informações', value: 'informacao' },
                    { text: '', value: 'subinfo' },
                    { text: 'Status', value: 'status' },


                ],
                desserts: [
                    {
                        name: 'Helper sede',
                        informacao:'Versao',
                        subinfo: 'V1.8.55xa - bug vibra',
                        status: ''

                    },
                    {
                        informacao:'Serial',
                        subinfo: '06060E06 | 5356051A | 4EF70DDC | F5000000',
                        status: ''
                    },
                    {
                        informacao:'MAC',
                        subinfo: '00:04:A3:8E:7B:81',
                        status: ''
                    },
                    {
                        informacao:'Giroflex',
                        subinfo: '0',
                        status: ''
                    },
                    {
                        informacao:'Termometro',
                        subinfo: '\'-68\' , \' -68\' , \' -68\' , \' -68\' , \' -68\'',
                        status: ''
                    },
                    {
                        informacao:'Alimentacao',
                        subinfo: 'RedeAC',
                        status: 'Ligado',

                    },
                    {
                        subinfo: 'Nobreak',
                        status: 'Desligado',

                    },
                    {
                        informacao:'Sensor',
                        subinfo: 'Usuario',
                        status: 'Desativado'
                    },
                    {
                        subinfo: 'Vibracao',
                        status: 'Desativado'

                    },
                    {
                        subinfo: 'Umidade',
                        status: 'Desativado'

                    },
                    {
                        subinfo: 'Entrada',
                        status: 'Desativado'

                    },
                    {
                        informacao:'Porta',
                        subinfo: 'Superior',
                        status: 'Ativado'

                    },
                    {
                        subinfo: 'Inferior',
                        status: 'Desligado'

                    },
                    {
                        informacao:'Luminosidade',
                        subinfo: '1',
                        status: ''
                    },
                    {
                        informacao:'EstadoMSG',
                        subinfo: 'AGUARDANDO',
                        status: ''
                    },
                    {
                        informacao:'tempo_msg',
                        subinfo: '0',
                        status: ''

                    },



                ],
            }
        },
        methods:{
            getStatus(status){
                if (status == '') return 'transparent'
                if (status=='Desativado')  return 'red'
                if (status=='Desligado') return 'red'
                else return 'green'

            },

        }
    }
</script>

<style scoped>


</style>