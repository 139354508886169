<template>

  <div class="grey darken-3" style="height: 100%">
    <loading-overlay :active="page.loading.isLoading" :is-full-page="page.loading.fullPage"/>
    <v-row justify="center">
      <v-col md="3">
        <v-card class="">
          <v-container justify="center">
            <v-row justify="center">
                <v-img min-width="30px" max-width="200px" :src="myImage"></v-img>
            </v-row>
            <v-row justify="center">
              <v-col>
                <h2 class="texto text-center">Central de Controle Helper - CCO</h2>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12">
                <v-form>
                  <v-text-field
                      v-model="email"
                      solo
                      label="Email">
                  </v-text-field>
                  <v-text-field
                      @keydown.enter="login"
                      v-model="password"
                      type="password"
                      solo
                      label="Senha">
                  </v-text-field>
                  <v-col class="text-center">
                    <v-btn color="success"
                         :loading="onLoading"
                         :disabled="onLoading"
                         @click="login()">Entrar</v-btn>
                  </v-col>
<!--                  <v-checkbox-->
<!--                      v-model="lembraUser"-->
<!--                      label="Lembrar senha"-->
<!--                      color="success">-->
<!--                  </v-checkbox>-->
                </v-form>
              </v-col>
            </v-row>
            <v-row justify="center">
              <v-col>
                <small class=" text-muted ">v{{version}}</small>
              </v-col>
            </v-row>
          </v-container>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import bus from 'src/bus'
import MaterialCard from 'src/components/MaterialCard'

export default {
  name: "Login",
  MaterialCard,
  data: () => ({
    page :{
      loading: {
        isLoading: true,
        fullPage: true
      },
      alert: {
        show : false,
        state : "danger",
        message : "",
      },
    },
    onLoading: false,
    config: "",
    email: "",
    password: "",
    lembraUser: "",
    myImage: require('src/assets/logo.png'),
    version: require('src/../package.json').version
  }),
  methods: {
    login() {
      this.page.loading.isLoading = true;
      this.onLoading = true;
      this.axios.post('/usuarios/login', {email: this.email, password: this.password})
          .then(response => {
            //console.log('resp', response.data)
            let user = response.data;
            if (user.user.email === this.email) {
              localStorage.setItem('email', user.user.email);
              sessionStorage.setItem('user', JSON.stringify(user));
              this.axios.defaults.headers.common['Authorization'] = 'Bearer ' + user.token;
              bus.$emit('login', true);
            }
            this.onLoading = false;
          })
          .catch(error => {
            bus.$emit('login', false);
            let message = (error.code === 'ECONNABORTED') ? "Falha na conexão com o Banco de Dados!" : error.response.data.error;
            this.page.alert = {
              show: true,
              state: "error",
              message: message,
            }

            this.$fire({
              title: "Erro",
              text: this.page.alert.message,
              type: this.page.alert.state
            })
            //console.log(message);
            this.onLoading = false;
          });
      this.page.loading.isLoading = false;
    }
  },
  watch: {
    lembraUser() {
      //console.log('lembra user')
      localStorage.setItem('lembra_user', this.lembraUser);
      if (!this.lembraUser) localStorage.removeItem('email');
    }
  },
  mounted() {
    this.lembraUser = JSON.parse(localStorage.getItem('lembra_user'));
    if (this.lembraUser) this.email = localStorage.getItem('email');
    if (sessionStorage.getItem('user')) {
      bus.$emit('login', true);
    }
    this.config = {
      nomeLocal:process.env.VUE_APP_SETOR,
      socket:process.env.VUE_APP_SOCKET,
      API:process.env.VUE_APP_API
    };
    this.page.loading.isLoading = false;
  }
}
</script>

<style scoped lang="css">
.texto {
  color: #424242;
}

</style>
