import { render, staticRenderFns } from "./MaterialCard.vue?vue&type=template&id=3b756eb4&scoped=true&"
import script from "./MaterialCard.vue?vue&type=script&lang=js&"
export * from "./MaterialCard.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3b756eb4",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VDivider } from 'vuetify/lib/components/VDivider';
installComponents(component, {VCard,VCardActions,VCardText,VDivider})
