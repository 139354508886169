<template xmlns:v-slot="http://www.w3.org/1999/XSL/Transform">
  <div>
    <v-app-bar dark app>
      <v-app-bar-nav-icon @click="mudaDrawer"></v-app-bar-nav-icon>
      <v-toolbar-title class="icone">{{title}}</v-toolbar-title>

      <v-spacer></v-spacer>

<!--      <v-menu-->
<!--        v-if="['Super', 'SysAdmin'].includes(permissao)"-->
<!--        offset-y-->
<!--        right-->
<!--        origin="center"-->
<!--        transition="scale-transition"-->
<!--      >-->
<!--        <template v-slot:activator="{ on }">-->
<!--          <v-btn text v-on="on">-->
<!--            <v-icon class="icone_lateral">mdi-lan-connect</v-icon>-->
<!--            <span class="d-none d-sm-flex d-md-flex">Conexão Remota</span>-->
<!--          </v-btn>-->
<!--        </template>-->

<!--        <v-list>-->
<!--          <v-list-item v-for="(item, i) in connect" :key="i" :href="item.url" target="_blank">-->
<!--            <v-list-item-icon class="icone_list">-->
<!--              <v-icon>{{item.icon}}</v-icon>-->
<!--            </v-list-item-icon>-->
<!--            <v-list-item-title>{{item.title }}</v-list-item-title>-->
<!--          </v-list-item>-->
<!--        </v-list>-->
<!--      </v-menu>-->

      <v-menu
        v-if="['Super', 'SysAdmin', 'Admin'].includes(this.permissao)"
        offset-y
        right
        origin="center"
        transition="scale-transition"
      >
        <template v-slot:activator="{ on }">
          <v-btn text v-on="on">
            <v-icon class="icone_lateral">mdi-account-plus</v-icon>
            <span class="d-none d-sm-flex">Cadastro</span>
          </v-btn>
        </template>

        <v-list>
          <v-list-item v-for="(item, i) in items" :key="i" @click="escolher(item.rota)">
            <v-list-item-icon class="icone_list">
              <v-icon>{{item.icon}}</v-icon>
            </v-list-item-icon>
            <v-list-item-title>{{ item.title}}</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
      <v-tooltip down>
        <template v-slot:activator="{ on }">
          <v-btn v-on="on" @click="logout()">
            <v-icon>mdi-logout</v-icon>
          </v-btn>
        </template>
        <span>Logout</span>
      </v-tooltip>
    </v-app-bar>
    <v-snackbar
      bottom
      center
      :color="notification.color"
      :timeout="notification.timeout"
      v-model="snackbar"
    >
      {{notification.message}}
      <v-spacer></v-spacer>
      <v-btn icon :dark="notification.dark" @click="navega(notification.url)">
        <v-icon>mdi-arrow-right</v-icon>
      </v-btn>
      <v-btn icon :dark="notification.dark" @click="snackbar=false">
        <v-icon>mdi-close-circle</v-icon>
      </v-btn>
    </v-snackbar>
  </div>
</template>

<script>
import bus from "../bus";
export default {
  name: "Topbar",
  data: () => ({
    permissao: "",
    snackbar: false,
    notification: { color: "grey" },
    notifications: [
      "Mike, John responded to your email",
      "You have 5 new tasks",
      "You're now a friend with Andrew",
      "Another Notification",
      "Another One"
    ],
    // title: "ESCOLA MAIS SEGURA",
    title: "Central de Controle de Operações",

    items: [
      { title: "Usuários",rota: "cadusuario",form: "usuario",icon: "mdi-account-multiple" },
      { title: "Helpers", rota: "cadhelper", form: "helper", icon: "mdi-cctv" },
      // {
      //   title: "Mobile",
      //   rota: "cadmobile",
      //   form: "mobile",
      //   icon: "mdi-cellphone-iphone"
      // }
    ],
    // connect: [
    //   { title: "Team Viewer", icon: "mdi-teamviewer", url: "https://start.teamviewer.com/pt/main.aspx" },
    //   { title: "VNC", icon: "mdi-console-network" }
    // ],
    audio: new Audio("sounds/Notificacao.mp3")
  }),
  watch: {
    $route(val) {
      this.title = val.name;
    }
  },
  methods: {
    logout() {
      sessionStorage.clear();
      bus.$emit("login", false);
    },
    navega(url) {
      if (url.includes("://")) window.open(url);
      else this.$router.push(url).catch(err => {});
    },
    mudaDrawer() {
      bus.$emit("mudaDrawer");
    },
    escolher(rota) {
      this.$router.push({ name: rota }).catch(err => {});
      //this.$router.push('/home')
    },
    // chamaSite() {
      //console.log('estou aqui!!');
      //console.log(this.connect);
    // }
  },
  mounted() {
    //console.log('mounted top')
    this.permissao = JSON.parse(sessionStorage.user).user.permissao;

    //SE NAO FOR SUPER / SYSADMIN, REMOVER MENU HELPERS
    if (!['Super', 'SysAdmin'].includes(this.permissao)) {
      this.items.pop();
    }
    //SE NAO FOR SUPER / SYSADMIN / ADMIN, REMOVER MENU USUÁRIOS
    if (!['Super', 'SysAdmin', 'Admin'].includes(this.permissao)) {
      this.items.pop();
    }


    bus.$on("notification", args => {
      //console.log("TOPBAR", args);
      this.snackbar = true;
      this.notification = args;
      this.audio.play().catch(() => {});
    });
    bus.$on("dismiss_notification", () => {
      this.snackbar = false;
    });

   // bus.$emit("notification", {
    //  color: "grey",
   //   url: "http://www.google.com.br/search?q=tempo",
   //   message: `Olá ${JSON.parse(sessionStorage.user).user.nome}`,
   //   timeout: 3000
    //});
  },
  beforeDestroy() {
    bus.$off("notification");
    bus.$off("dismiss_notification");
  }
};
</script>

<style lang="css" scoped>
.icone_text {
  padding-left: 5px !important;
  /*margin-right:5px !important;*/
}
.icone_list {
  margin-right: 5px !important;
}
.icone_lateral {
  margin-right: 5px !important;
}
</style>
