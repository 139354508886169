import Vue from 'vue';
import Vuetify from 'vuetify/lib';
import 'vuetify/dist/vuetify.min.css';

Vue.use(Vuetify);

import pt from 'vuetify/es5/locale/pt'

// import pt_br from './i18n/vuetify/pt_br'

export default new Vuetify({
    icons: {
        iconfont: 'mdi',
    },
    lang: {
        locales: { pt },
        current: 'pt',
    },
});
