<template>
    <v-footer app dark>
        &copy; Helper Tecnologia {{ new Date().getFullYear() }} - v{{ versao }}
    </v-footer>
</template>
<script>
    export default {
        name: "Footer",
        data: () => ({
            versao: require('src/../package.json').version
        })
    }
</script>
<style scoped>

</style>