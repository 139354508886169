<template>
  <v-container>
    <v-row justify="center">
      <v-col cols="12" md="9">
        <v-card>
          <v-toolbar dark>
            <v-toolbar-title>Configuração do servidor</v-toolbar-title>
          </v-toolbar>
          <v-card-text>
            <label>API (Alterar localhost pelo IP da máquina se necessário Ex: http://192.169.200.1:4000)</label>
            <v-text-field v-model="API" disabled></v-text-field>
            <v-btn color="info" @click="salva" v-if="0">Salvar</v-btn>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
    <ocorrencia>
      
    </ocorrencia>
  </v-container>
</template>

<script>
import Ocorrencia from "../components/Ocorrencia";
export default {
  name: "Configuracoes",
  components: {
    Ocorrencia
  },
  data() {
    let config= db.getConfig();
    return {
      API: process.env.VUE_APP_API
    };
  },
  methods: {
    salva() {
      let config = {
        API: this.API,
      };
      localStorage.setItem("config", JSON.stringify(config));
      this.$router.go();
    }
  },
  mounted() {
    this.API = process.env.VUE_APP_API;
  }
};
</script>

<style>
</style>