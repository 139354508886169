<template xmlns:v-slot="http://www.w3.org/1999/XSL/Transform">
  <div>
    <loading-overlay :active="page.loading.isLoading" :is-full-page="page.loading.fullPage"/>

    <!--  MODAL ALTERAR SENHA-->
    <v-dialog
        v-model="modal_password"
        persistent
        max-width="600px"
    >
      <v-card>

        <v-form ref="form" lazy-validation>
          <v-card-title>
            <span class="headline">Alterar Senha</span>
          </v-card-title>
          <v-card-text>
            <v-container>
              <v-row>
                <v-col cols="12">
                  <v-text-field
                      v-model="password"
                      label="Senha"
                      name="password"
                      prepend-icon="mdi-lock"
                      type="password"
                      :rules="senhaRules"
                  />

                  <v-text-field
                      v-model="confirmPassword"
                      label="Confirmar Senha"
                      name="confirmPassword"
                      prepend-icon="mdi-lock"
                      type="password"
                      :rules="confirmPasswordRules"
                  />
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
                color="blue darken-1"
                text
                @click="modal_password = false"
            >
              Close
            </v-btn>
            <v-btn
                color="success"
                text
                @click.prevent="changePassword()"
            >
              Save
            </v-btn>
          </v-card-actions>
        </v-form>
      </v-card>
    </v-dialog>

    <!--  Cadastrar Usuario-->
    <v-toolbar flat dark>
      <v-toolbar-title>{{ this.tabTitle }}</v-toolbar-title>
    </v-toolbar>

    <v-tabs color="grey lighten-2" vertical v-model="page.abaAtiva">
      <v-tab>
        <v-icon left>mdi-pencil</v-icon>
        <span class="d-none d-sm-flex">Cadastro</span>
        <v-spacer></v-spacer>
      </v-tab>
      <v-tab>
        <v-icon left>mdi-table</v-icon>
        <span class="d-none d-sm-flex">Lista</span>
        <v-spacer></v-spacer>
      </v-tab>

      <v-tab-item>
        <v-form ref="form" v-model="valid" lazy-validation>

          <v-container>

            <v-row>

              <!--CAMPO DE NOME DE USUÁRIO-->
              <v-col cols="12" md="6">
                <v-text-field
                    type="text"
                    label="Nome de Usuário"
                    v-model="usuario.username"
                    class="purple-input"
                    placeholder="michael_123"
                    :rules="fieldRequired"
                    required
                />
              </v-col>

              <v-col cols="12" md="3">
                <v-select
                    v-model="usuario.permissao"
                    :items="opcoes"
                    :rules="fieldRequired"
                    label="Permissão"
                    required
                ></v-select>
              </v-col>

              <!--CAMPO DE SETOR-->
              <v-col cols="12" md="3">
                <v-text-field
                    label="Setor"
                    v-model="usuario.setor"
                    :disabled="editaSetor"
                    required
                />
              </v-col>

            </v-row>

            <v-row>

              <!--CAMPO DE EMAIL-->
              <v-col cols="12">
                <v-text-field
                    label="Email"
                    v-model="usuario.email"
                    class="purple-input"
                    placeholder="Email"
                    :rules="emailRules"
                    required
                />
              </v-col>

            </v-row>

            <v-row>

              <!--CAMPO DE NOME-->
              <v-col cols="12">
                <v-text-field
                    label="Nome"
                    v-model="usuario.nome"
                    class="purple-input"
                    placeholder="Nome"
                    :rules="fieldRequired"
                    required
                />
              </v-col>

            </v-row>

            <v-row>
              <v-col cols="12">
                <v-text-field
                    label="Telefone"

                    :masked="masked"
                    type="tel"
                    v-model="usuario.telefone"
                    class="purple-input"
                    placeholder="(41)3000-4000"
                    :rules="fieldRequired"
                    required
                />
              </v-col>
            </v-row>

            <v-row v-if="!checkExist()">

              <v-col cols="12" md="6">
                <v-text-field
                    type="password"
                    v-model="usuario.password"
                    label="Senha"
                    name="password"
                    placeholder="*******"
                    class="purple-input"
                    :rules="senhaRules"
                />
              </v-col>
              <v-col cols="12" md="6">
                <v-text-field
                    type="password"
                    v-model="usuario.password2"
                    label="Confirmar Senha"
                    placeholder="*******"
                    class="purple-input"
                    :rules="confirmarSenhaRules"
                />
              </v-col>

            </v-row>


            <v-row>

              <!--BUTTONS -->
              <v-col class="align-content-end">
                <v-btn v-if="checkExist()" class="mr-2" color="success" @click.prevent="editData()">
                  Atualizar
                </v-btn>
                <v-btn v-if="!checkExist()" class="mr-2" color="success" @click.prevent="addData()">
                  Salvar
                </v-btn>
                <v-btn class="mr-2" color="warning" @click.prevent="clearForm()">
                  Cancelar
                </v-btn>
                <v-btn v-if="checkExist()" class="mr-2" dark color="info" @click="modal_password = true">
                  Alterar Senha
                </v-btn>
                <v-btn v-if="canRemove()" class="mr-2" color="error" @click.prevent="deleteData()">
                  Remover
                </v-btn>


              </v-col>

            </v-row>

          </v-container>

        </v-form>
      </v-tab-item>

      <!-- LISTAGEM-->
      <v-tab-item>

        <v-data-table
            :headers="headers"
            :items="items"
        >
          <template v-slot:body="{ items }">
            <tbody>
            <tr v-for="item in items" :key="item.email">

              <td v-if="itsMe(item._id)">
                <v-badge
                    color="green"
                    dot
                >
                  {{ item.nome }}
                </v-badge>
              </td>
              <td v-else>{{ item.nome }}</td>

              <td>{{ item.email }}</td>
              <td>{{ item.telefone }}</td>
              <td>{{ item.setor }}</td>
              <td>{{ item.permissao }}</td>
              <td @click="getData(item._id)"><a>
                <v-icon>mdi-account-edit</v-icon>
              </a></td>
            </tr>
            </tbody>
          </template>
        </v-data-table>
      </v-tab-item>
    </v-tabs>
  </div>
  <!--  fim do cadastro do Usuario-->
</template>
<script>
import vuetify from "@/plugins/vuetify";

export default {
  name: "CadUsuario",
  title: "ok",
  data() {
    return {
      email: '',
      password: '',
      confirmPassword: '',
      modal_password: false,

      page : {
        loading: {
          isLoading: true,
          fullPage: true
        },
        alert: {
          show : false,
          state : "danger",
          message : "",
        },
        abaAtiva: 0,
      },
      tabTitle: "Cadastro de Usuários",
      token: "",
      masked: false,
      setor: JSON.parse(sessionStorage.user).user.setor,
      valid: true,
      singleExpand: false,
      editaSetor: true,
      usuario: {
        permissao: "",
        setor: "",
        // nome: "joel",
        // username: "joel",
        // email: "j@j2.com",
        // telefone: "123456789",
        // password: "123",
        // password2: "123",
      },
      headers: [
        {
          sortable: true,
          text: 'Nome',
          value: 'nome'
        },
        {
          sortable: true,
          text: 'Email',
          value: 'email'
        },
        {
          sortable: true,
          text: 'Telefone',
          value: 'telefone'
        },
        {
          sortable: true,
          text: 'Setor',
          value: 'setor',
          // align: 'right'
        },
        {
          sortable: true,
          text: 'Permissão',
          value: 'permissao',
          // align: 'right'
        },
        {
          sortable: true,
          text: 'Editar',
          value: 'editar'
        }

      ],
      select: null,
      opcoes: [
        'Operador',
        'Admin',
        'SysAdmin',
        'Super',
        'Teacher',
      ],

      items: [],

      //USUARIO
      fieldRequired: [
        v => !!v || 'Campo Obrigatório',
      ],
      emailRules: [
        v => !!v || 'E-mail é Obrigatório',
        v => /.+@.+[.]+.+/.test(v) || 'E-mail deve ser válido',
      ],
      senhaRules: [
        v => !!v || 'Campo Obrigatório',
        v => (!!v && v.length >= 6) || 'Mínimo 6 caracteres, podendo conter apenas números e letras.',
      ],
      confirmarSenhaRules: [
        v => !!v || 'Campo Obrigatório',
        v => v === this.usuario.password || 'A confirmação de senha difere da senha.',
      ],
      confirmPasswordRules: [
        v => !!v || 'Campo Obrigatório',
        v => v === this.password || 'A confirmação de senha difere da senha.',
      ],
      //FIM USUSARIO

    }

  },
  methods: {

    checkExist() {
      for (let item of this.items) {
        if (this.usuario._id === item._id) {
          return true
        }
      }
      return false
    },

    itsMe(_id) {
      return (
          (JSON.parse(sessionStorage.getItem('user')).user._id === _id)
      );
    },

    canRemove() {
       if(this.checkExist() && (this.usuario._id !== "") && (!this.itsMe(this.usuario._id))){
         let perm = JSON.parse(sessionStorage.getItem('user')).user.permissao;
         switch(this.usuario.permissao){
           case "Super":
           case "SysAdmin":
             return (perm === "Super");
           case "Admin":
           case "Operador":
             return (["Super","SysAdmin","Admin"].includes(perm));
         }
       }


      return false;
    },

    getData(_id) {
      this.page.loading.isLoading = true;
      for (let item of this.items) {
        //console.log(".", item.email, this.cadastros.usuario.email);
        if (item._id === _id) {

          Object.keys(item).forEach(key => {
            this.usuario[key] = item[key];
          });
          this.usuario.password = this.usuario.password2 = ''
          this.page.abaAtiva = 0 //ABA DE CADASTRO
        }
      }
      this.page.loading.isLoading = false;
    },

    listData() {
      this.page.loading.isLoading = true;
      let uri = '/usuarios';
      this.axios.get(uri)
          .then(response => {
            let usuarios
            switch(JSON.parse(sessionStorage.getItem('user')).user.permissao) {
              case "Super":
              case "SysAdmin":
                usuarios = response.data
                break;
              case "Admin":
                usuarios = response.data.filter(
                    usuario => (
                        (["Admin", "Operador"].includes(usuario.permissao)) && //LISTAR APENAS ADMIN E AGENTES
                        (JSON.parse(sessionStorage.getItem('user')).user.setor === usuario.setor) //E DO MESMO SETOR
                    )
                )
                break;
              case "Operador":
                usuarios = []
                break;
            }

            this.items = [...usuarios];
            this.page.loading.isLoading = false;
          });
    },

    addData() {
      if (!this.$refs.form.validate()) {
        return
      }
      //VERIFICAR SE JÁ NÃO EXISTE email DEFINIDO
      this.page.loading.isLoading = true;
      let uri = '/usuarios/add';
      this.axios.post(uri, this.usuario)
          .then((res) => {
            this.showSuccess( res.data.message );
            this.page.loading.isLoading = false;
            this.listData();
            this.clearForm();
          }).catch(err => {
            this.showError( err.response.data );
            this.page.loading.isLoading = false;
          }
      );
    },

    editData() {
      this.page.loading.isLoading = true;
      let uri = `/usuarios/update/${this.usuario._id}`;
      let user = this.usuario;
      delete user.password;
      delete user.password2;
      this.axios.post(uri, user)
          .then((res) => {
            this.showSuccess( res.data.message );
            this.page.loading.isLoading = false;
            this.listData();
            this.clearForm();
          }).catch((err) => {
            this.showError( err.data );
            this.page.loading.isLoading = false;
          });

    },

    deleteData() {
      if (this.canRemove()) {
        this.$confirm("Tem certeza que deseja remover o usuário?","Atenção", "question", {
          confirmButtonText: 'Sim',
          cancelButtonText: 'Não',
        }).then((returns) => {
            if(returns) {
              this.page.loading.isLoading = true;
              let uri = `/usuarios/delete/${this.usuario._id}`;
              this.axios.delete(uri)
                  .then((res) => {
                    this.listData();
                    this.clearForm();
                    this.showSuccess( res.data.message );
                    this.page.loading.isLoading = false;
                  }).catch((err) => {
                    this.showError( err.data );
                    this.page.loading.isLoading = false;
                  });
            }
          }
        ).catch(() => {
          this.showSuccess( "Operação cancelada!" )
        });

      }

    },

    changePassword() {
      if (!this.$refs.form.validate()) {
        return false;
      }

      this.page.loading.isLoading = true;

      //ALTERAR A SENHA DO USER EM QUESTÃO
      let uri = `/usuarios/update/${this.usuario._id}`;
      let user = { 'password':this.password }
      this.modal_password = false;

      this.axios.post(uri, user)
        .then((res) => {
          this.page.loading.isLoading = false;
          this.showSuccess( res.data.message );
        }).catch((err) => {
          this.page.loading.isLoading = false;
          this.showError( err.data );
      });

    },

    //endregion FIM US

    showSuccess( message ){
      this.page.alert = {
        show: true,
        state: "success",
        message: message,
      }
      this.$fire({
        title: "Sucesso",
        text: this.page.alert.message,
        type: this.page.alert.state
      })
    },

    showError( message ){
      this.page.alert = {
        show: true,
        state: "error",
        message: message,
      }
      this.$fire({
        title: "Erro",
        text: this.page.alert.message,
        type: this.page.alert.state
      })
    },

    clearForm(){
      this.setor = JSON.parse(sessionStorage.getItem('user')).user.setor
      this.usuario = {
        permissao: "Operador",
        setor: this.setor,
      }
      this.$refs.form.resetValidation();
      this.page.abaAtiva = 1;
    },

  },
  mounted() {
    this.token = JSON.parse(sessionStorage.getItem('user')).token
    this.axios.defaults.headers.common['Authorization'] = 'Bearer ' + this.token;
    this.usuario.setor = this.setor;
    if (JSON.parse(sessionStorage.getItem('user')).user.permissao === "Super"){
      this.editaSetor = false;
    } else {
      this.editaSetor = true;
      this.opcoes.pop(); // retira da lista o Super
      this.opcoes.pop(); // Retira da lista o SysAdmin
    }
    this.listData();
    this.page.loading.isLoading = false;
  },

  watch: {
    modal_password( v ) {
      if(!v){
        this.$refs.form.reset();
      }

    },
    page: {
      handler(o){
        // do stuff
        this.tabTitle = o.abaAtiva ? 'Lista de Usuários Cadastrados' : 'Cadastro Usuários';
      },
      deep: true
    }
  },
}

</script>

<style scoped>
.listaCad {
  margin-top: 0px !important;
}

.formCad {
  margin-bottom: 0px !important;
}
</style>
