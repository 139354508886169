<template>
  <v-data-table
      :headers="headers"
      :items="items"
      :cliente="cliente"
      sort-by="audio"
      class="elevation-1"
  >
    <template v-slot:item.audio="{ item }">
      <v-chip
          :color="item.color"
          dark
      >
        {{ item.audio }}
      </v-chip>
    </template>
    <template v-slot:top>
      <v-toolbar
          flat
      >
        <v-toolbar-title>Agendamentos</v-toolbar-title>
        <v-divider
            class="mx-4"
            inset
            vertical
        ></v-divider>
        <v-spacer></v-spacer>

      </v-toolbar>
    </template>
    <template v-slot:item.actions="{ item }">
      <v-icon
          small
          @click="deleteItem(item)"
      >
        mdi-delete
      </v-icon>
    </template>
    <template v-slot:no-data>
        Sem agendamentos cadastrados
    </template>
  </v-data-table>
</template>
<script>
import bus from "src/bus";
export default {
  name: "AgendaTable",
  props: {
    events: Array,
    cliente: String,
  },
  data: () => ({

    page : {
      loading: {
        isLoading: true,
        fullPage: true
      },
      alert: {
        show : false,
        state : "danger",
        message : "",
      },
    },
    headers: [
      { text: 'Áudio',      value: 'audio',     sortable: true, align: 'start' },
      { text: 'Período',    value: 'period',    sortable: false },
      { text: 'Dias',       value: 'days',      sortable: false },
      { text: 'Intervalo',  value: 'interval',  sortable: false },
      { text: 'Horário',    value: 'time',      sortable: false },
      { text: 'Ação',    value: 'actions',   sortable: false },
    ],
    items: [],
    styles: {
      time_style: new Intl.DateTimeFormat("pt-BR" , {timeStyle: "short"}),
    }
  }),
  watch: {
    events() {
      this.initialize();
    },
  },

  mounted () {

    // configura socket
    this.socketConfig();

    this.initialize();

  },

  methods: {
    //=============================================
    //==================== CRUD ===================
    //=============================================

    checkExist(id) {
      for (let item of this.items) {
        if (id === item._id) {
          return true
        }
      }
      return false
    },

    canRemove(id) {
      return (
          this.checkExist(id) &&
          !(["Teacher"].includes(JSON.parse(sessionStorage.getItem('user')).user.permissao))
      );
    },

    //=============================================
    //=================== LAYOUT ==================
    //=============================================

    socketConfig() {

      bus.$on("DELETE_AGENDA", s =>{

        bus.$emit("notification", {
          color: "primary",
          message: "Agendamento deletado",
          timeout: 3000
        });

        let index = this.events.findIndex(function(o){
          return o._id === s;
        })
        if (index !== -1) this.events.splice(index, 1);

        this.initialize();
        // this.$socket.emit("MSG_HELPER", {
        //   cliente: this.cliente,
        //   requisicao: "GET_AGENDA",
        //   dados: "",
        //   status: 0
        // });

      })

    },

    initialize () {
      this.items = [];
      this.events.forEach((e) => {
        let start = new Date(e.start);
        let end = new Date(e.end);

        let time_start = this.styles.time_style.format(start);
        let time_end = this.styles.time_style.format(end);
        let interval = e.details.interval + "m";

        //const da = new Intl.DateTimeFormat('pt-BR', { day: '2-digit' }).format(start);

        let days = "Todos os dias";
        let data = {};
        switch (e.details.type) {
            //DIÁRIO
          case "Diário":
            data = {
              audio: e.name,
              period: e.details.type,
              time: time_start + ' - ' + time_end,
              interval: interval,
              days: days,
              color: e.color,
              _id: e._id,
            };
            break;
            // SEMANAL
          case "Semanal":
            //CRIAR FUNÇÃO GLOBAL PARA ISSO
            days = e.details.days.split( ',').map(day => day.charAt(0).toUpperCase() + day.slice(1)).join([', ']);
            data = {
              audio: e.name,
              period: e.details.type,
              time: time_start + ' - ' + time_end,
              interval: interval,
              days: days,
              color: e.color,
              _id: e._id,
            };
            break;
          //PERSONALIZADO
          case "Único":
            let date_start = (new Intl.DateTimeFormat('pt-br')).format(start);
            let date_end = (new Intl.DateTimeFormat('pt-br')).format(end);
            days = e.details.days.split( ',').map(day => day.charAt(0).toUpperCase() + day.slice(1)).join([', ']);
            data = {
              audio: e.name,
              period: "De " + date_start + ' à ' + date_end,
              time: time_start + ' - ' + time_end,
              interval: interval,
              days: days,
              color: e.color,
              _id: e._id,
            };
            break;
        }
        this.items.push(data);
      });
    },

    deleteItem (item) {
      if (this.canRemove(item._id)) {
        this.$confirm("Tem certeza que deseja remover o agendamento?","Atenção", "question", {
          confirmButtonText: 'Sim',
          cancelButtonText: 'Não',
        }).then((returns) => {
              if(returns) {
                this.page.loading.isLoading = true;
                this.$socket.emit("MSG_HELPER", {
                  cliente: this.cliente,
                  requisicao: "DELETE_AGENDA",
                  dados: item._id,
                  status: 0,
                });
              }
          }).catch(() => {
            this.showSuccess( "Operação cancelada!" )
        });
        return true;

      } else {
        this.showError("Você não tem permissão para remover este agendamento!");
      }
    },

    showSuccess( message ){
      this.page.alert = {
        show: true,
        state: "success",
        message: message,
      }
      this.$fire({
        title: "Sucesso",
        text: this.page.alert.message,
        type: this.page.alert.state
      })
    },

    showError( message ){
      this.page.alert = {
        show: true,
        state: "error",
        message: message,
      }
      this.$fire({
        title: "Erro",
        text: this.page.alert.message,
        type: this.page.alert.state
      })
    },


  },
}
</script>