<template>
    <v-main>
        <d-top-bar></d-top-bar>
        <d-side-bar></d-side-bar>
        <d-content ></d-content>
        <d-footer></d-footer>
    </v-main>
</template>

<script>
    import DSideBar from "./SideBar";
    import DTopBar from "./TopBar";
    import DContent from "./Content";
    import DFooter from "./Footer";

    export default {
        name: "DashBoard",
        components:{
            DSideBar,
            DTopBar,
            DContent,
            DFooter
        }
    }
</script>

<style scoped>

</style>