<template xmlns:v-slot="http://www.w3.org/1999/XSL/Transform">
    <v-card>
        <v-card-title>
            Portas Helper
            <v-btn icon @click="getSensores"><v-icon>mdi-refresh</v-icon></v-btn>
            <v-spacer></v-spacer>
            <v-text-field
                    v-model="search"
                    append-icon="mdi-search"
                    label="Search"
                    single-line
                    hide-details
            ></v-text-field>
        </v-card-title>
        <v-data-table
                :headers="headers"
                :items="sensores"
                :icon="sensores.connect"
                :search="search"
        >
            <template v-slot:item.status="{ item }" >
                <v-chip :color="getStatus(item.status)" dark>{{ item.status }}</v-chip>
            </template>


        </v-data-table>

    </v-card>
</template>

<script>
    import bus from 'src/bus'
    export default {
        name: "ConexaoHelper",
        data() {
            return {
                search: '',
                icon: "mdi-refresh",
                headers: [
                    {
                        text: 'Nome Helper',
                        align: 'left',
                        sortable: false,
                        value: 'name',
                    },
                    { text: 'Porta', value: 'porta' },
                    { text: 'Descrição', value: 'descricao' },
                    { text: 'Status', value: 'status' },


                ],
                sensores: [
                    {
                        name: 'Helper sede',
                        porta:'S01',
                        descricao: 'Botão power',
                        status:'OFF',
                    },
                    {
                        porta:'S02',
                        descricao: 'Botão',
                        status:'ON',


                    },
                    {
                        porta:'S03',
                        descricao: 'humidade',
                        status:'OFF',

                    },
                    {
                        porta:'S04',
                        descricao: '?',
                        status:'ON',

                    },
                    {
                        porta:'S07',
                        descricao: 'Trote',
                        status:'OFF',

                    },
                    {
                        porta:'S08',
                        descricao: 'vandalismo',
                        status:'ON',

                    },
                    {
                        porta:'S09',
                        descricao: '?',
                        status:'OFF',

                    },
                    {
                        porta:'S41',
                        descricao: 'MENSAGEM 1',
                        status:'OFF',
                    },
                    {
                        porta:'S42',
                        descricao: 'MENSAGEM 2',
                        status:'OFF',
                    },
                    {
                        porta:'S18',
                        descricao: 'INTERFONE',
                        status:'OFF',
                    },
                    {
                        porta:'S17',
                        descricao: 'SOM AMBIENTE',
                        status:'OFF',
                    },
                ],
            }
        },
        methods:{
            getStatus(status){
                if (status == 'ON') return 'green'
                else  return 'red'
            },
            getSensores(){
                this.$socket.emit('MSG_HELPER',{
                    cliente:`192.169.200.211@${JSON.parse(sessionStorage.user).user.setor}`,
                    requisicao:'GET_SENSORES',
                    dados:'',
                    status:0,
                })
            }
        },
        mounted() {
            bus.$on('GET_SENSORES', s=> {
                //console.log('dados', s)
                let str = s.split(';')
                for (let sensor of this.sensores) {
                    for (let sn of str) {
                        if (sensor.porta === sn.split('=')[0]) {
                            sensor.status = sn.split('=')[1]
                        }
                    }
                }
            })
        },
        beforeDestroy() {
            bus.$off('GET_SENSORES')
        }
    }
</script>

<style scoped>


</style>
