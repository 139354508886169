<template xmlns:v-slot="http://www.w3.org/1999/XSL/Transform">
  <div>
    <!-- Seleção do Helper -->

    <v-toolbar flat dark height="80px">
      <v-toolbar-items class="mt-10">
        <v-autocomplete
          label="Selecione o Totem Helper:"
          chips
          v-model="selectHelper"
          :items="listHelpers"
          color="white"
        />
        <v-btn icon @click="getHelpers">
          <v-icon>mdi-refresh</v-icon>
        </v-btn>
      </v-toolbar-items>
    </v-toolbar>

    <v-alert class="ma-3"
             v-if="!page.helper_active"
             outlined
             type="error"
             border="left"
    >Totem Helper não selecionado ou inativo. Por favor, selecione outro equipamento!</v-alert>

    <v-tabs color="grey" vertical v-if="page.helper_active">

      <v-tab>
        <v-icon left>mdi-speaker</v-icon>
        <span class="d-none d-sm-flex">Áudios no Helper</span>
        <v-spacer></v-spacer>
      </v-tab>

      <v-tab>
        <v-icon left>mdi-archive</v-icon>
        <span class="d-none d-sm-flex">Enviar arquivo</span>
        <v-spacer></v-spacer>
      </v-tab>
<!--      <v-tab>-->
<!--        <v-icon left>mdi-text-to-speech</v-icon>-->
<!--        <span class="d-none d-sm-flex">Texto-para-fala (TTS)</span>-->
<!--        <v-spacer></v-spacer>-->
<!--      </v-tab>-->

      <v-tab>
        <v-icon left>mdi-text-to-speech</v-icon>
        <span class="d-none d-sm-flex">Gravar Áudio</span>
        <v-spacer></v-spacer>
      </v-tab>

      <!--    ÁUDIO NO HELPER     -->
      <v-tab-item>
        <v-container>
          <v-row justify="center">
            <v-col cols="12" md="8" xs="2">
              <vuetify-audio ref="player1" :title="(title !== undefined) ? ('Áudio selecionado: ' + title) : 'Selecione o áudio'" :file="file" :ended="audioFinish"></vuetify-audio>
            </v-col>
          </v-row>
          <v-row justify="center">
            <v-col cols="12" md="8" xs="2">
              <template>
                <v-card
                    class="mx-auto"
                    tile
                >
                  <v-list rounded>
                    <v-subheader>Lista de Áudios no Helper</v-subheader>
                    <v-list-item-group
                        color="primary"
                    >
                      <v-list-item
                          @click="selecionarAudio(play.file)"
                          v-for="(play,i) in players"
                          :key="i"
                      >
                        <v-list-item-icon>
                          <v-icon @click="dialog=true" v-if="play.file!= ''">mdi-pencil</v-icon>
                        </v-list-item-icon>
                        <v-list-item-content>
                          <v-list-item-title v-text="play.file"></v-list-item-title>
                        </v-list-item-content>
                      </v-list-item>
                    </v-list-item-group>
                  </v-list>
                </v-card>
              </template>

            </v-col>
          </v-row>
        </v-container>
      </v-tab-item>

      <!--    ENVIO DE ARQUIVOS     -->
      <v-tab-item>
        <v-container>
          <h4 class="mb-4">Enviar áudios para o {{ selectHelper }}</h4>
          <v-file-input
            v-model="arquivo"
            counter
            label="Arquivo"
            multiple
            placeholder="Selecione o(s) arquivo(s) de áudio para o envio"
            prepend-icon="mdi-paperclip"
            outlined
            :show-size="1000"
          >
            <template v-slot:selection="{ index, text }">
              <v-chip v-if="index < 2" color="blue lighten-1" dark label small>{{ text }}</v-chip>
              <span
                v-else-if="index === 2"
                class="overline grey--text text--darken-3 mx-2"
              >+{{ arquivo.length - 2 }} Arquivos(s)</span>
            </template>
          </v-file-input>
          <v-btn class="mr-4" @click.prevent="confirma=true">Enviar</v-btn>
          <!--    instruções de envio de áudio -->
          <v-row justify="center">
            <v-col cols="12" md="4">
              <v-list>
                <v-subheader>Instruções: antes de enviar o áudio</v-subheader>
                <v-list-item-group>
                  <v-alert
                    outlined
                    type="warning"
                    border="left"
                  >O formato do áudio deve ser MP4, WAV, AAC ou OGG</v-alert>
                  <v-alert
                    outlined
                    type="warning"
                    border="left"
                  >Se o áudio existir no Helper, irá sobrescrever o existente</v-alert>
                </v-list-item-group>
              </v-list>
            </v-col>
          </v-row>
        </v-container>
      </v-tab-item>

      <!--    TTS     -->
<!--      <v-tab-item>-->
<!--        <v-container>-->
<!--          <v-alert class="ma-3"-->
<!--                   outlined-->
<!--                   type="warning"-->
<!--                   border="left"-->
<!--          >Esta funcionalidade está em desenvolvimento!</v-alert>-->
<!--          <h3>Digite o texto para ser convertido para áudio</h3>-->
<!--          <h5>(O sistema não reconhece acentuação, substitua se necessário)</h5>-->
<!--          <h5>Ex: ç por ss, Á por AH</h5>-->
<!--          <v-text-field v-model="nomeTTS" placeholder="Nome do arquivo salvo" disabled></v-text-field>-->
<!--          <v-text-field v-model="tts" placeholder="Texto que será convertido para voz" disabled></v-text-field>-->
<!--          <v-btn @click="processarTexto()" disabled>Processar texto</v-btn>-->
<!--        </v-container>-->
<!--      </v-tab-item>-->

      <!--    Gravar Áudio     -->
<!--      <v-tab-item>-->
<!--      <v-container>-->
<!--        <v-alert class="ma-3"-->
<!--                 outlined-->
<!--                 type="warning"-->
<!--                 border="left"-->
<!--        >Esta funcionalidade está em desenvolvimento!</v-alert>-->
<!--        <h3>Gravar Áudio</h3>-->
<!--          <Audio-Recorder></Audio-Recorder>-->
<!--          <Audio-Player></Audio-Player>-->
<!--      </v-container>-->
<!--      </v-tab-item>-->

    </v-tabs>

    <v-card>
      <!--//region Dialog para editar o nome do áudio-->
      <div>
        <v-dialog v-model="dialog">
          <v-card>
            <v-card-title class="headline grey lighten-2" primary-title>Editar Áudio</v-card-title>
            <v-card-text>
              <h4>Atenção! Qualquer alteração de um arquivo que conste no agendamento, pode ocasionar falhas na execução de mensagens no Helper</h4>
            </v-card-text>
            <v-card-text>
                <v-text-field v-model="title"></v-text-field>
            </v-card-text>

            <v-divider></v-divider>

            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="primary" @click.prevent="deletarAudio(title)">Deletar</v-btn>
              <v-btn color="primary" @click.prevent="dialog=false">Cancelar</v-btn>
              <v-btn color="primary" @click.prevent="editar(title)">Salvar</v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </div>
      <!--                                    //endregion FIM áudio-->
      <div>
        <v-dialog v-model="confirma" width="700">
          <v-card>
            <v-card-title class="headline red lighten-2" primary-title>Atenção</v-card-title>

            <v-card-text
              class="headline bold mt-5"
            >Verifique o arquivo selecionado. Arquivos com nome já existente no Helper irá ser sobreescrito!</v-card-text>

            <v-divider></v-divider>

            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="primary" @click="confirma=false">CANCELAR</v-btn>
              <v-btn color="primary" @click="enviar(), confirma=false">CONFIRMAR</v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </div>
    </v-card>

  </div>
</template>

<script>
import bus from "src/bus";
// import AudioRecorder from '../components/recorder';
// import AudioPlayer from '../components/player';

import VuetifyAudio from "src/components/VuetifyAudio";
//import path from 'path'
//import { setAudio } from 'src/controllers/audio'
let sw = null;
export default {
  components: {
    "vuetify-audio": VuetifyAudio,
    // "Audio-Recorder": AudioRecorder,
    // "Audio-Player": AudioPlayer
  },
  data() {
    return {
      page : {
        loading: {
          isLoading: true,
          fullPage: true
        },
        alert: {
          show : false,
          state : "danger",
          message : "",
        },
        helper_active: false,
        form: {
          diasSemana : false,
          period : false,
        },
      },
      nomeTTS: "",
      tts: "",
      dialog: false,
      confirma: false,
      selectHelper: "",
      listaHelpers: [],
      arquivo: [],
      file: "",
      title: "",
      oldName: "",
      files: [],
      players: [
        { file: "" }
      ],
    };
  },
  watch: {

    selectHelper(e) {
      this.page.helper_active = false;
      this.helperSelecionado = {};
      this.cliente = {};

      if(e!="") {
        // altera o valor do Helper selecionado
        this.helperSelecionado = this.listaHelpers.filter(u => u.properties.nome === e)[0];
        // monta a ID do cliente
        this.cliente = `${this.helperSelecionado.properties.ip_helper}@${this.helperSelecionado.properties.setor}`;
        localStorage.setItem("HelperSelecionado", this.cliente);
        // zera a lista de audios
        this.players = [];
        this.title = "Nenhum áudio selecionado";
        // pede para o Helper a lista de audios
        this.listAudio();
      }
    }

  },
  computed: {

    listHelpers() {
      let helpers = [];
      for (let helper of this.listaHelpers) {
        helpers.push(helper.properties.nome);
      }
      return helpers;
    }

  },
  mounted() {
    // REQUERIDO! Essa linha configura o token nas requisições e deve existir em todas as rotas que usam axios
    this.axios.defaults.headers.common["Authorization"] = "Bearer " + JSON.parse(sessionStorage.getItem("user")).token;
    // pega helper na DB
    this.getHelpers();
    // configura socket
    this.socketConfig();
  },
  methods: {
    //=============================================
    //================ FUNCTIONS ==================
    //=============================================

    socketConfig() {

      bus.$on("LIST_AUDIO", s => {
        this.files = s;
        this.players = [];
        for (let file of s.files) {
          this.players.push({ file });
        }
        // this.file = this.getPathFile(s.files[0]);
        this.title = this.file;
        this.page.helper_active = true

        this.notify("Lista de áudios recuperada", 'info');

      });

      bus.$on("DELETE_AUDIO", s => { this.notify(s, 'info');})

    },

    getHelpers() {
      this.page.loading.isLoading = true;
      this.page.helper_active = false;
      this.axios.get("/helper")
        .then(response => {
          let temp = [...response.data];

          //VERIFICAR SE É SUPER, SE FOR, NÃO FILTRA OS HELPERS
          if (JSON.parse(sessionStorage.user).user.permissao.search(/super/gi) === -1) {
            this.listaHelpers = temp.filter(el => {
              return el.properties.setor === JSON.parse(sessionStorage.user).user.setor
            })
          } else {
            // PREENCHE A LISTA COM TODOS OS HELPERS
            this.listaHelpers = temp
          }

          //preenche a lista com os nomes dos Helpers para usar no dropdown
          for (let i in this.listaHelpers) {
            this.listaHelpers[i].text = this.listaHelpers[i].nomeHelper
          }

          // pré-seleciona o primeiro Helper da lista
          if (typeof this.$route.params.helper === "undefined") {
            this.selectHelper = "";
          } else {
            this.selectHelper = this.$route.params.helper;
            // this.page.helper_active = true;
          }
          this.page.loading.isLoading = false;
        });
    },

    processarTexto() {
      this.axios
        .get(
          "https://api.voicerss.org/?key=c9ca52a2dd8748c1a84cfc752e91ff49&hl=pt-br&src=" +
            this.tts +
            "&c=AAC&r=0",
          {
            withCredentials: false
          }
        )
        .then(result => {
          //console.log(result.data)
          // result.data.pipe(fs.createWriteStream('audio.mp4'))
          //     .on('finish', () => res.sendFile(path.resolve(__dirname + '/audio.mp4')))
        })
        .catch(err => {
          //console.log('erro')
        });
    },

    enviar() {
      for (let file of this.arquivo) {
        var stream = file;
      }

      this.$socket.emit("MSG_HELPER", {
          cliente: this.cliente,
          requisicao: "FILE_TRANSFER",
          dados:{
            name: stream.name,
            data: stream,
          },
          status: 0
      });

      bus.$on("FILE_TRANSFER", s => {
        this.notify("Áudio enviado com sucesso", 'green')
      });

      this.dialog = false;
      this.listAudio();

    },

    selecionarAudio(file) {
      this.file = this.getPathFile(file);
      this.title = file;
      this.oldName = file;
      this.$refs.player1.stop();
      this.$refs.player1.reload();
      this.$vuetify.goTo(0)
      // this.$refs.player1.$data.playing = this.$refs.player1.stop();
    },

    listAudio() {
      //console.log('requeriu audios')
      this.$socket.emit("MSG_HELPER", {
        cliente: this.cliente,
        requisicao: "LIST_AUDIO",
        dados: "",
        status: 0
      });
    },

    audioFinish() {
      //this.players.push('Fim do Áudio');
    },

    getPathFile(file) {
      var pei = 1;
      if(pei === 0){
        //console.log(this.files.server + this.files.path + "\\" + file)
        //console.log(file)
        //console.log(this.files.server);
        //console.log(this.files.path);
         return this.files.server + this.files.path + "\\" + file;
      }
      if(this.title != '...'){


        this.$socket.emit('MSG_HELPER', {
          cliente: this.cliente,
          requisicao: 'VOICE',
          dados: {
            name : file,
            arquivo : ""
         },
          status: 0
        });

        this.$socket.on('MSG_CLIENTE', async (data) =>{
            //console.log(data);
            var audio_blob;
            var blob;
            const ipPC = this.cliente.split('@')[0];
            const local = this.cliente.split('@')[1];
            if (!(data.cliente.includes(local) && data.cliente.includes((ipPC)))) return

            switch(data.requisicao) {
                //region #TRANSF DE ARQ
                case 'VOICE':
                  function getaudio (arrayBuffer) {
                  blob = new Blob([arrayBuffer], { 'type' : 'audio/wav' });
                   //console.log(blob);
                   audio_blob = window.URL.createObjectURL(blob);
                  }
                  getaudio(data.dados.arquivo);

                  //var file = new File([blob], data.dados.name);

                  //console.log(audio_blob);
                  //console.log(this.files.server + this.files.path + "\\" + file)
                  this.file = audio_blob;

                 return file;

                break
            }
        })
      }
    },

    notify(message, type) {
      bus.$emit("notification", {
        color: type,
        message: message,
        timeout: 3000,
      })
    },

    //=============================================
    //================== CRUD =====================
    //=============================================

    editar(file) {
      this.$socket.emit("MSG_HELPER", {
        cliente: this.cliente,
        requisicao: "RENAME_AUDIO",
        dados: {
          oldFile: this.oldName,
          newFile: file
        },
        status: 0
      });
      this.dialog = false;
      this.listAudio();
    },

    deletarAudio(file) {
      this.$socket.emit("MSG_HELPER", {
        cliente: this.cliente,
        requisicao: "DELETE_AUDIO",
        dados: file,
        status: 0
      });
      this.dialog = false;
      this.listAudio();
    },


  },
  beforeDestroy() {
    bus.$off("LIST_AUDIO");
    bus.$off("DELETE_AUDIO");
  }
};
</script>

<style scoped>
.tile {
  background: lightgrey;
}
</style>
