<template>
    <router-view></router-view>
</template>

<script>
    export default {
        name: "Content",
        data(){
            return{
                snackbar:true,
            }
        }
    }
</script>

<style scoped>

</style>