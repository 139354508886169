<template>
    <v-container fill-height fluid>
    </v-container>
</template>

<script>
    export default {
        name: "PageNotFound",
        created(){
            window.location.href = "/";
        }
    }
</script>
