<template>
  <v-card class="text-center">
    <v-card-subtitle>{{title}}</v-card-subtitle>
    <v-card-text>
      <v-btn outlined icon class="blue--text mr-1" @click.native="playing ? pause() : play()" :disabled="loaded === false">
        <v-icon v-if="playing === false || paused === true">mdi-play</v-icon>
        <v-icon v-else>mdi-pause</v-icon>
      </v-btn>
      <v-btn outlined icon class="blue--text  mr-1" @click.native="stop()" :disabled="loaded === false">
        <v-icon>mdi-stop</v-icon>
      </v-btn>
      <v-btn outlined icon class="blue--text  mr-1" @click.native="mute()" :disabled="loaded === false">
        <v-icon v-if="isMuted === false">mdi-volume-high</v-icon>
        <v-icon v-else>mdi-volume-off</v-icon>
      </v-btn>
      <v-btn outlined icon class="blue--text  mr-1" @click.native="loaded ? download() : reload()">
        <v-icon v-if="loaded === false">mdi-refresh</v-icon>
        <v-icon v-else>mdi-download</v-icon>
      </v-btn>
<!--      <v-slider @click.native="setPosition()" v-model="percentage" dark></v-slider>-->
      <p class="mt-3">{{ currentTime }} / {{ duration }}</p>
    </v-card-text>
    <audio id="player" ref="player"  v-on:ended="ended" v-on:canplay="canPlay" :src="file"  ></audio>
  </v-card>

</template>
<script>
const formatTime = (second) => {
  let time = new Date(second * 1000).toISOString().substr(11, 8)
  return time
}
export default {
  name: 'vuetify-audio',

  props: {
    title: String,
    file: {
      type: String,
      default: null
    },
    autoPlay: {
      type: Boolean,
      default: false
    },
    ended: {
      type: Function,
      default: () => {},
    },
    canPlay: {
      type: Function,
      default: () => {},
    },
  },
  computed: {
    duration: function () {
      return this.audio ? formatTime(this.totalDuration) : ''
    },
  },
  data () {
    return {
      isMuted: false,
      loaded: false,
      playing: false,
      paused: false,
      percentage: 0,
      currentTime: '00:00:00',
      audio: undefined,
      totalDuration: 0,
    }
  },

  methods: {
    setPosition () {
      this.audio.currentTime = parseInt(this.audio.duration / 100 * this.percentage);
    },
    stop () {
      this.paused = this.playing = false
      this.audio.pause()
      this.audio.currentTime = 0
    },
    play () {
      if (this.playing) return
      this.paused = false
      this.audio.play()
      this.playing = true
    },
    pause () {
      this.paused = !this.paused;
      (this.paused) ? this.audio.pause() : this.audio.play()
    },
    download () {
      this.audio.pause()
      window.open(this.file, 'download')
    },
    mute () {
      this.isMuted = !this.isMuted
      this.audio.muted = this.isMuted
      this.volumeValue = this.isMuted ? 0 : 75
    },
    reload () {
      this.audio.load();
    },
    _handleLoaded: function () {
      if (this.audio.readyState >= 2) {
        if (this.autoPlay) this.audio.play()
        this.loaded = true
        this.totalDuration = parseInt(this.audio.duration)
      } else {
        throw new Error('Failed to load sound file')
      }
    },
    _handlePlayingUI: function (e) {
      this.percentage = this.audio.currentTime / this.audio.duration * 100
      this.currentTime = formatTime(this.audio.currentTime)
    },
    _handlePlayPause: function (e) {
      if (e.type === 'pause' && this.paused === false && this.playing === false) {
        this.currentTime = '00:00:00'
      }
    },
    _handleEnded () {
      this.paused = this.playing = false;
    },
    init: function () {
      this.audio.addEventListener('timeupdate', this._handlePlayingUI);
      this.audio.addEventListener('loadeddata', this._handleLoaded);
      this.audio.addEventListener('pause', this._handlePlayPause);
      this.audio.addEventListener('play', this._handlePlayPause);
      this.audio.addEventListener('ended', this._handleEnded);
    },
  },
  mounted () {
    this.audio = this.$refs.player;
    this.init();
  },
  beforeDestroy () {
    this.audio.removeEventListener('timeupdate', this._handlePlayingUI)
    this.audio.removeEventListener('loadeddata', this._handleLoaded)
    this.audio.removeEventListener('pause', this._handlePlayPause)
    this.audio.removeEventListener('play', this._handlePlayPause)
    this.audio.removeEventListener('ended', this._handleEnded);
  }

}
</script>
