import Vue from 'vue'
import App from './App.vue'
import router from './router'
import vuetify from './plugins/vuetify'
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';
import io from 'socket.io-client';
import VueAxios from 'vue-axios';
import axios from 'axios';
import Embed from 'v-video-embed';
import VueSimpleAlert from "vue-simple-alert";
import { format } from 'date-fns';

Vue.filter('formatDate', function(date) {
  if (date) {
    return format(date, 'HH:mm MM/dd/yyyy');
  }
});

Vue.use(VueSimpleAlert);
Vue.use(Embed);
Vue.component('loading-overlay', Loading)
Vue.config.productionTip = false

//console.log("API", process.env.VUE_APP_API)
const socket = io(`${process.env.VUE_APP_SOCKET}`)
const instance = axios.create({
  baseURL:`${process.env.VUE_APP_API}`,
  timeout:5000
})
Vue.use(VueAxios, instance)
Vue.prototype.$socket = socket
Vue.prototype.$listaHelpers = []
Vue.prototype.$jwt = ''

new Vue({
  router,
  vuetify,
  render: h => h(App)
}).$mount('#app')

const prod = process.env.NODE_ENV === 'production'
const shouldSW = 'serviceWorker' in navigator && prod
if (shouldSW) {
  navigator.serviceWorker.register('/service-worker.js').then(() => {
    //console.log('service Worker Registered! ')
  })
}