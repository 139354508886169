<template>
    <v-card>
        <v-card-title>
            Helpers
            <v-spacer></v-spacer>
            <v-text-field
                    v-model="search"
                    append-icon="mdi-search"
                    label="Search"
                    single-line
                    hide-details
            ></v-text-field>
        </v-card-title>
        <v-data-table
                :headers="headers"
                :items="desserts"
                :search="search"
        ></v-data-table>
    </v-card>
</template>

<script>
    export default {
        name: "BatimentoHelper",
        data () {
            return {
                setor:'',
                search: '',
                headers: [
                    {
                        text: 'Nome Helper',
                        align: 'left',
                        sortable: false,
                        value: 'name',
                    },
                    { text: 'Descrição', value: 'descricao' },
                    { text: 'Nome_local', value: 'nomeLocal' },
                    { text: 'IP_Helper', value: 'ipHelper' },
                    { text: 'Nome_Drone', value: 'nomeDrone' },
                    { text: 'Criado em', value: 'criadoEm' },
                    { text: 'Criador', value: 'criador' },
                    { text: 'Ultima Edição', value: 'ultimaEdicacao' },
                    { text: 'Editor', value: 'editor' },
                ],
                desserts: [
                    {
                        name: 'Helper sede',
                        descricao: 'helper',
                        nomeLocal: JSON.parse(sessionStorage.user).user.setor,
                        ipHelper: '192.169.201.1',
                        nomeDrone: 123456789,
                        criadoEm: '28-11-2019',
                        criador:'OSIAS',
                        ultimaEdicacao:'28-11-2019',
                        editor:'Joelson'

                    },
                    {
                        name: 'Helper mini Sede',
                        descricao: 'mini helper',
                        nomeLocal: JSON.parse(sessionStorage.user).user.setor,
                        ipHelper: '192.169.200.66',
                        nomeDrone: 123456789,
                        criadoEm: '28-11-2019',
                        criador:'OSIAS',
                        ultimaEdicacao:'28-11-2019',
                        editor:'Joelson'

                    },
                    {
                        name: 'Helper Resende',
                        descricao: 'helper',
                        nomeLocal: JSON.parse(sessionStorage.user).user.setor,
                        ipHelper: '192.169.201.1',
                        nomeDrone: 123456789,
                        criadoEm: '28-11-2019',
                        criador:'OSIAS',
                        ultimaEdicacao:'28-11-2019',
                        editor:'Joelson'

                    },
                    {
                        name: 'Helper Resende',
                        descricao: 'helper',
                        nomeLocal: JSON.parse(sessionStorage.user).user.setor,
                        ipHelper: '192.169.201.1',
                        nomeDrone: 123456789,
                        criadoEm: '28-11-2019',
                        criador:'OSIAS',
                        ultimaEdicacao:'28-11-2019',
                        editor:'Joelson'

                    },
                    {
                        name: 'Helper Resende',
                        descricao: 'helper',
                        nomeLocal: JSON.parse(sessionStorage.user).user.setor,
                        ipHelper: '192.169.201.1',
                        nomeDrone: 123456789,
                        criadoEm: '28-11-2019',
                        criador:'OSIAS',
                        ultimaEdicacao:'28-11-2019',
                        editor:'Joelson'

                    },
                    {
                        name: 'Helper Resende',
                        descricao: 'helper',
                        nomeLocal: JSON.parse(sessionStorage.user).user.setor,
                        ipHelper: '192.169.201.1',
                        nomeDrone: 123456789,
                        criadoEm: '28-11-2019',
                        criador:'OSIAS',
                        ultimaEdicacao:'28-11-2019',
                        editor:'Joelson'

                    },
                    {
                        name: 'Helper Resende',
                        descricao: 'helper',
                        nomeLocal: JSON.parse(sessionStorage.user).user.setor,
                        ipHelper: '192.169.201.1',
                        nomeDrone: 123456789,
                        criadoEm: '28-11-2019',
                        criador:'OSIAS',
                        ultimaEdicacao:'28-11-2019',
                        editor:'Joelson'

                    },
                    {
                        name: 'Helper Resende',
                        descricao: 'helper',
                        nomeLocal: JSON.parse(sessionStorage.user).user.setor,
                        ipHelper: '192.169.201.1',
                        nomeDrone: 123456789,
                        criadoEm: '28-11-2019',
                        criador:'OSIAS',
                        ultimaEdicacao:'28-11-2019',
                        editor:'Joelson'

                    },
                    {
                        name: 'Helper Resende',
                        descricao: 'helper',
                        nomeLocal: JSON.parse(sessionStorage.user).user.setor,
                        ipHelper: '192.169.201.1',
                        nomeDrone: 123456789,
                        criadoEm: '28-11-2019',
                        criador:'OSIAS',
                        ultimaEdicacao:'28-11-2019',
                        editor:'Joelson'

                    },
                    {
                        name: 'Helper Resende',
                        descricao: 'helper',
                        nomeLocal: JSON.parse(sessionStorage.user).user.setor,
                        ipHelper: '192.169.201.1',
                        nomeDrone: 123456789,
                        criadoEm: '28-11-2019',
                        criador:'OSIAS',
                        ultimaEdicacao:'28-11-2019',
                        editor:'Joelson'

                    },
                    {
                        name: 'Helper Resende',
                        descricao: 'helper',
                        nomeLocal: JSON.parse(sessionStorage.user).user.setor,
                        ipHelper: '192.169.201.1',
                        nomeDrone: 123456789,
                        criadoEm: '28-11-2019',
                        criador:'OSIAS',
                        ultimaEdicacao:'28-11-2019',
                        editor:'Joelson'

                    },
                    {
                        name: 'Helper Resende',
                        descricao: 'helper',
                        nomeLocal: JSON.parse(sessionStorage.user).user.setor,
                        ipHelper: '192.169.201.1',
                        nomeDrone: 123456789,
                        criadoEm: '28-11-2019',
                        criador:'OSIAS',
                        ultimaEdicacao:'28-11-2019',
                        editor:'Joelson'

                    },{
                        name: 'Helper Resende',
                        descricao: 'helper',
                        nomeLocal: JSON.parse(sessionStorage.user).user.setor,
                        ipHelper: '192.169.201.1',
                        nomeDrone: 123456789,
                        criadoEm: '28-11-2019',
                        criador:'OSIAS',
                        ultimaEdicacao:'28-11-2019',
                        editor:'Joelson'

                    },

                ],
            }
        },
        mounted(){
            this.setor = JSON.parse(sessionStorage.user).user.setor
        }
    }
</script>

<style scoped>


</style>
