<template xmlns:v-slot="http://www.w3.org/1999/XSL/Transform">
    <v-card>
        <v-card-title>
            Conexão
            <v-spacer></v-spacer>
            <v-text-field
                    v-model="search"
                    append-icon="mdi-search"
                    label="Search"
                    single-line
                    hide-details
            ></v-text-field>
        </v-card-title>
        <v-data-table
                :headers="headers"
                :items="desserts"
                :icon="desserts.connect"
                :search="search"
        >
            <template v-slot:item.status="{ item }" >
                <v-chip :color="getStatus(item.status)" dark>{{ item.status }}</v-chip>
            </template>

            <template v-slot:item.connect="{ item }" class="text-center">
                <v-icon @click="getconnect"  :icon="item.connect" >{{ item.connect }}</v-icon>
            </template>
        </v-data-table>

    </v-card>
</template>

<script>
    export default {
        name: "ConexaoHelper",
        data () {
            return {
                setor:'',
                search: '',
                icon: "mdi-refresh",
                headers: [
                    {
                        text: 'Nome Helper',
                        align: 'left',
                        sortable: false,
                        value: 'name',
                    },
                    { text: 'Descrição', value: 'descricao' },
                    { text: 'Nome_local', value: 'nomeLocal' },
                    { text: 'IP_Helper', value: 'ipHelper' },
                    { text: 'Status', value: 'status' },
                    { text: 'Teste Conexão', value: 'connect' },
                    { text: 'Resultado', value: 'resultado' },
                    { text: 'Ultima Edição', value: 'ultimaEdicacao' },
                    { text: 'Editor', value: 'editor' },
                ],
                desserts: [
                    {
                        name: 'Helper sede',
                        descricao: 'helper',
                        nomeLocal: JSON.parse(sessionStorage.user).user.setor,
                        ipHelper: '192.169.201.1',
                        status:'conectado',
                        connect:'mdi-sync',
                        resultado: 'conexão estabelecida',
                        ultimaEdicacao: '28-11-2019',
                        editor: 'Joelson'


                    },
                    {
                        name: 'Helper mini Sede',
                        descricao: 'mini helper',
                        nomeLocal: JSON.parse(sessionStorage.user).user.setor,
                        ipHelper: '192.169.200.66',
                        status:'desconectado',
                        connect:'mdi-sync',
                        resultado: 'erro ao conectar',
                        ultimaEdicacao: '28-11-2019',
                        editor: 'Renan'

                    },

                ],
            }
        },
        methods:{
            getStatus(status){
                if (status == 'conectado') return 'green'
                else  return 'red'

            },
            getconnect(){
                for (let item of this.headers){
                    //console.log(item.value)
                }

                //console.log("aqui")
            }
        },
        mounted(){
            this.setor = JSON.parse(sessionStorage.user).user.setor
        }
    }
</script>

<style scoped>


</style>
