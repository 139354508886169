<template>
    <v-navigation-drawer
        app
        dark
        floating
        expand-on-hover
        v-model="drawer">
        <v-list-item class="logo mt-3">
            <v-img :src="logo"></v-img>
        </v-list-item>
        <v-list >

            <v-list-item
                v-if="(permissao==='Teacher')" to="/professor">
              <v-list-item-action>
                <v-icon>mdi-alert-circle-outline</v-icon>
              </v-list-item-action>
              <v-list-item-content>
                <v-list-item-title>Professor</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-list-item
                v-if="!(permissao==='Teacher')" to="/map">
                    <v-list-item-action>
                        <v-icon>mdi-map</v-icon>
                    </v-list-item-action>
                    <v-list-item-content>
                        <v-list-item-title>Map</v-list-item-title>
                    </v-list-item-content>
            </v-list-item>

            <v-list-item
                v-if="!(permissao==='Teacher')" to="/atendimento">
                <v-list-item-action>
                    <v-icon>mdi-headset</v-icon>
                </v-list-item-action>
                <v-list-item-content>
                    <v-list-item-title>Atendimento</v-list-item-title>
                </v-list-item-content>
            </v-list-item>

            <v-list-item
                    v-if="permissao!=='Teacher'"
                    to="/agenda">
                <v-list-item-action>
                    <v-icon>mdi-calendar-check</v-icon>
                </v-list-item-action>
                <v-list-item-content>
                    <v-list-item-title>Agenda</v-list-item-title>
                </v-list-item-content>
            </v-list-item>

            <v-list-item
                    v-if="permissao === 'Super' || permissao === 'SysAdmin'|| permissao === 'Admin'"
                    to="/audio">
                <v-list-item-action>
                    <v-icon>mdi-volume-high</v-icon>
                </v-list-item-action>
                <v-list-item-content>
                    <v-list-item-title>Gerenciar Áudios</v-list-item-title>
                </v-list-item-content>
            </v-list-item>

            <v-list-item
                    v-show="false"
                    to="/helpers">
                <v-list-item-action>
                        <v-icon>mdi-glass-stange</v-icon>
                    </v-list-item-action>
                <v-list-item-content>
                        <v-list-item-title>Helpers</v-list-item-title>
                    </v-list-item-content>
            </v-list-item>

            <v-list-item v-if="permissao === 'Super'" to="/configuracoes">
                <v-list-item-action>
                  <v-icon>mdi-monitor</v-icon>
                </v-list-item-action>
                <v-list-item-content>
                    <v-list-item-title>Configurações</v-list-item-title>
                </v-list-item-content>
            </v-list-item>
        </v-list>
    </v-navigation-drawer>
</template>

<script>
    import bus from '../bus'

    export default {
        name: "Sidebar",
        data: () => {
            return {
                permissao:'',
                hover: false,
                drawer: null,
                logo: require('src/assets/icone.png')
            }
        },
        mounted() {
            bus.$on("mudaDrawer", ()=> {
                this.drawer = !this.drawer;
                //bus.$emit("drawerStatus", this.drawer);
            });
            this.permissao = JSON.parse(sessionStorage.user).user.permissao
        },
        beforeDestroy() {
            bus.$off('mudaDrawer')
        }
    }
</script>

<style lang="css" scoped>
    .logo{
        margin-left: -10px;
        width: 75px
    }

</style>
